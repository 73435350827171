import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// create offer load data for persist
const shortenConversionRequest = (id) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  };
  return axios(`${apiURL}/url/${id}`, config);
};
const getemailtemplateRequest = (accessTokan) => {
  return axios.get(`${apiURL}/docAndClause/EMAIL_TEMPLATE`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
const offerTableRequest = (accessToken, offerId, sorter, sortWay, selectedCriteria) => {
  const addCriteriaKeys =selectedCriteria && selectedCriteria.length===0?"EMPTY":selectedCriteria?.join('&&');
  return axios.get(`${apiURL}/offerTable/${offerId}`, {
    headers: {
      authorization: `bearer ${accessToken}`,
      accept: "application/json",
    },
    params: {
      skip: 0,
      limit: 20,
      sortBy: sorter,
      sortWave: sortWay,
     ...(addCriteriaKeys?{addCriteriakeys: addCriteriaKeys}:{})
    },
  });
};



const offerStatusChange = (accessToken, payload) => {
  return axios.put(`${apiURL}/offerDocument/offerStatusChange`, payload, {
    headers: {
      authorization: `bearer ${accessToken}`,
      accept: "application/json",
    },
  });
};

const postStarsRequest = (accessTokan, id, stars) => {
  return axios.post(
    // `${apiURL}/contractOfferRating/${id}`,
    `${apiURL}/offerRating/${id}`,
    {
      rate: stars,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};
//
// URLShort Select Offer Request
const URLShortSelectOfferRequest = (accessToken, data) => {
  return axios.post(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};

// select offer
const selectOfferRequest = (accessToken, propertyId, data, markedAsHidden
  ) => {
  return axios.put(
    `${apiURL}/offerTable/${propertyId}`,
    {
      offerId: data.offerId,
      // status: e,
      markedAsHidden:markedAsHidden
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};

const hiddenOfferRequest = (accessToken, propertyId, data, markedAsHidden
  ) => {
  return axios.put(
    `${apiURL}/offerTable/${propertyId}`,
    {
      offerId: data.offerId,
     markedAsHidden:markedAsHidden,
     documentId: data.CBSRId,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};

const checkedOfferRequest = (accessToken, id, checked, allData) => {
  return axios.put(
    // `${apiURL}/contractOffer/${id}`,
    `${apiURL}/offerTable/${id}`,
    {
      offerHasIssue: checked,
      offerId: allData.offerId,
      documentId: allData.CBSRId,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};

const inputFillRequest = (accessToken, id, value, allData) => {
  return axios.put(
    // `${apiURL}/contractOffer/${id}`,
    `${apiURL}/offerTable/${id}`,
    {
      transactionData: value,
      offerId: allData.offerId,
      documentId: allData.CBSRId,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};

const sendEmailToClientRequest = (accessToken, data) => {
  return axios.post(`${apiURL}/offerDocument/sendDocument`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const sendPasswordToClientRequest = (accessToken, data) => {
  return axios.post(`${apiURL}/offerDocument/sendOTP`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const shareOfferTableToClientRequest = (accessToken, data) => {
  return axios.post(`${apiURL}/sendOfferTable`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const restrictOfferTableToClientRequest = (accessToken, data) => {
  return axios.post(`${apiURL}/restrictUnrestrictOfferTable`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const getRestrictedClientOffetTableRequest = (accessToken, propertyId) => {
  return axios.get(`${apiURL}/offerTable/restrictedClients/${propertyId}`,  {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const getConstantsRequest = (accessToken, query) => {
  return axios.get(`${apiURL}/getConstants`, {
    params: {
      ...query,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const acceptOfferViaResureSignRequest = (accessToken, payload) => {
  return axios.post(`${apiURL}/resure-sign/acceptOffer`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });

};

const shareMessageToBuyerAgentsRequest = (accessToken, data) => {
  return axios.post(`${apiURL}/sendMultipleDocs`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};


export const offerTableApi = {
  selectOfferRequest,
  offerTableRequest,
  postStarsRequest,
  offerStatusChange,
  checkedOfferRequest,
  inputFillRequest,
  getemailtemplateRequest,
  URLShortSelectOfferRequest,
  shortenConversionRequest,
  sendEmailToClientRequest,
  sendPasswordToClientRequest,
  shareOfferTableToClientRequest,
  restrictOfferTableToClientRequest,
  getRestrictedClientOffetTableRequest,
  hiddenOfferRequest,
  getConstantsRequest,
  acceptOfferViaResureSignRequest,
  shareMessageToBuyerAgentsRequest
};
