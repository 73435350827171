
import moment from "moment";
import CryptoJS from "crypto-js";

/* -----------function to format a 10 digit phone no in the US format ---------------- */
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return""
  }
  let cleanedPhoneNo = phoneNumber.toString().replace(/\D/g, '');
        let formattedNumber = '';
        if (cleanedPhoneNo.length > 10) {
          cleanedPhoneNo = cleanedPhoneNo.substr(0, 10);
        }
        for (let i = 0; i < cleanedPhoneNo.length; i++) {
          if (i === 0) {
            formattedNumber += `(${cleanedPhoneNo[i]}`;
          } else if (i === 3) {
            formattedNumber += `) ${cleanedPhoneNo[i]}`;
          } else if (i === 6) {
            formattedNumber += ` - ${cleanedPhoneNo[i]}`;
          } else {
            formattedNumber += cleanedPhoneNo[i];
          }
  }
  return formattedNumber;
};

function isMobileApp() {
  return /(android|iphone|ipad|ipod)/i.test(navigator.userAgent) && /app/i.test(navigator.userAgent);
}
// Checking if the user is on a mobile device
const isMobileDevice = /Mobi|Android|iPhone|iPod|iPad|Windows Phone/i.test(navigator.userAgent);
// to get the more Accuracy
export function detectMobile() {
  return isMobileApp() || isMobileDevice;
}

export function detectWebView() {
  return window?.ReactNativeWebView != null;
}

export const truncateStr = (string, n) => {
  if (!string) return "";
  if (string.length <= n) return string;
  const truncated = string.substr(0, n - 3); 
  return `${truncated}...`;
};

export const  convertTimeInto24HourFormat= (time, source) =>{
  if (time && time !== "undefined") {
    const match = time.toString().match(/^(\d{1,2})(:)([0-5]\d)(:[0-5]\d)?\s?([APap][Mm])?$/);
    if (match) {
      let hours = parseInt(match[1]);
      const minutes = parseInt(match[3]);
      const isPM = /p/i.test(match[5]);

      if (isPM && hours < 12) {
        hours += 12;
      } else if (!isPM && hours === 12) {
        hours = 0;
      }
      // Converting to 24-hour format and format the result
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }
  }
  return source= "OFFER_TABLE"?"N/A":"";
}

export const formatStateValue = (inputValue) => {
  if (inputValue) {
    const cleanedValue = inputValue.replace(/[^A-Za-z]/g, "").toUpperCase();
    return cleanedValue.slice(0, 2);
  }
  return "";
};

export const formatAndParse = (value) => {
  if (!value) {
    return 0;
  }
  return parseFloat(value?.toString()?.replace(/[^0-9.]/g, '')) || 0;
};

export const getValuesFromCookies = async (cookieName) => {
  const cookies = document.cookie?.split('; ');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name.trim() === cookieName) {
      return value.trim();
    }
  }

  return null;
};

export const generateInitials = (fullName="") => {
  const init = fullName?.trim().split(" ");
  const initials = init ? init[0]?.[0]?.toUpperCase() + init[init.length - 1]?.[0]?.toUpperCase() : "";
  return initials;
}

export const filterEmptyAndUndefinedKeys = (data) => {
  if (!data || typeof data !== 'object') {
    return {};
  }
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => key !== undefined && key.trim() !== '')
  );
};
export const filterEmptyAndUndefinedValues = (data) => {
  try {
    if (!data || typeof data !== "object" || Array.isArray(data)) {
      return {};
    }

    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (!value || (typeof value === "string" && value.trim() === "")) {
          return false;
        }
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }
        if (typeof value === "object" && Object.keys(value).length === 0) {
          return false;
        }
        // Keeping everything else
        return true;
      })
    );
  } catch (error) {
    return {};
  }
};
export const formatDate = (date) => {
  if (!date ||  !moment(date).isValid()) {
    return "";
  }
  return moment(date).format("MM-DD-YYYY")
};

export const generateInitialsFromObj = (item) => {
  const { fullName, firstName, lastName } = item || {};
  if (!fullName && !firstName && !lastName) return "";

  let firstInitial = "";
  let lastInitial = "";

  if (fullName) {
    const init = fullName.trim().split(" ");
    firstInitial = init[0]?.[0]?.toUpperCase() || "";
    lastInitial = init.length > 1 ? init[init.length - 1]?.[0]?.toUpperCase() : "";
  } else {
    if (firstName) {
      firstInitial = firstName.trim()[0]?.toUpperCase() || "";
    }
    if (lastName) {
      lastInitial = lastName.trim()[0]?.toUpperCase() || "";
    }
  }

  return firstInitial + lastInitial;
};

export const capitalizeStr = (str) => {
  if (typeof str !== 'string' || !str.length) {
    return "";
  }
  return str[0].toUpperCase() + (str.length > 1 ? str.substring(1).toLowerCase() : "");
};

export const generateFullNameFromObj = (item) => {
  const { fullName, firstName, middleName, lastName } = item || {};
  if (typeof fullName === 'string' && fullName.trim()) {
    return fullName.trim();
  }

  const capitalizedFirstName = capitalizeStr(firstName);
  const capitalizedMiddleName = middleName && middleName !== "null" ? capitalizeStr(middleName) : "";
  const capitalizedLastName = capitalizeStr(lastName);

  const formattedFullName = [capitalizedFirstName, capitalizedMiddleName, capitalizedLastName].filter(Boolean).join(" ");
  return formattedFullName;
};

export const handleShowPicker = (event) => {
  try {
    event?.target?.showPicker();
  } catch (error) {
    console.log("Error occurred while showing the date or time picker");
  }
};

export const getCoAgentsIds = (coAgentData) => {
  let coAgentsIds = [];
  if (Array.isArray(coAgentData) && coAgentData.length) {
    coAgentData.forEach(el => {
      coAgentsIds.push(el._id);
    })
  }
  return coAgentsIds;
}
// export const getMonthDifference = ({startMonth, startYear, endMonth, endYear}) => {
//   console.log(startMonth, startYear, endMonth, endYear);
//   const totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
//   const months = [];
//   for (let i = 0; i < totalMonths; i++) {
//     const month = ((startMonth + i - 1) % 12) + 1; 
//     const year = startYear + Math.floor((startMonth + i - 1) / 12); 
//     months.push({ month, year });
//   }
//   return months;
// }

const mapSigners = (coClient, role) =>
  coClient.signers?.map(signer => ({
    ...signer,
    corpKey: coClient.key,
    openFor: role,
    isCorp: true
  })) || [];
  
  export const mapClientsForSendEmail = ({clients, role, agents}) => {
    const mergedClients = [...(clients?clients:[]), ...(agents?agents:[])];
    return (
      mergedClients.map((coClient) => ({
        ...coClient,
        openFor: coClient.isAgent ? coClient.openFor : role,
        isReadOnly: false,
        isAddedByUser: false,
        ...(coClient.isCorp
          ? {
              signers: mapSigners(coClient, role),
              isCorp: true,
            }
          : { isCorp: false }),
      })) || []
    );
  };

// Helper function to merge signers for corp clients
const mergeSigners = (existingSigners = [], newSigners = []) => {
  const seenSignerIds = new Set(existingSigners.map((signer) => signer.personId?._id || signer.key));
  return [
    ...existingSigners,
    ...newSigners.filter((newSigner) => {
      const signerId = newSigner.personId?._id || newSigner.key;
      return !seenSignerIds.has(signerId);
    })
  ];
};


const processClients = (clientIds = [], corpMap, individualMap) => {
  clientIds.forEach((client = {}) => {
    const { isCorp, key, personId, signers = [] } = client; 
    if (isCorp) {
      if (!corpMap.has(key)) {
        corpMap.set(key, { ...client, signers: signers || [] });
      } else {
        const existingCorpClient = corpMap.get(key);
        const mergedSigners = mergeSigners(existingCorpClient.signers, signers);
        corpMap.set(key, { ...existingCorpClient, signers: mergedSigners });
      }
    } else {
      const personIdValue = personId?._id || key; 
      if (!individualMap.has(personIdValue)) {
        individualMap.set(personIdValue, { ...client, signers: signers || [] });
      }
    }
  });
};

export const getcombinedClients = ({ allContractDocuments = [], condition }) => {
  const corpSellers = new Map();
  const individualSellers = new Map();
  const corpBuyers = new Map();
  const individualBuyers = new Map();
  allContractDocuments?.forEach((document = {}) => {
    if (condition === "SELLER" || !condition) {
      processClients(document?.sellerIds, corpSellers, individualSellers);
    }
    if (condition === "BUYER" || !condition) {
      processClients(document?.buyerIds, corpBuyers, individualBuyers);
    }
  });
  return {
    combinedSellerIds: condition === "BUYER" ? [] : [...corpSellers.values(), ...individualSellers.values()],
    combinedBuyerIds: condition === "SELLER" ? [] : [...corpBuyers.values(), ...individualBuyers.values()],
  };
};

  
export const removePersonObject = (corporateClients=[]) => {
  for(let i = 0; i<corporateClients?.length ; i++){
    if(corporateClients?.[i]?.isCorp){
      for(let j = 0; j<corporateClients?.[i]?.signers?.length ; j++){
        corporateClients[i].signers[j].personId = corporateClients?.[i]?.signers[j]?.personId?._id || corporateClients?.[i]?.signers[j]?.personId;
      }
    }
    else{
      corporateClients[i].personId = corporateClients?.[i]?.personId?._id || corporateClients?.[i]?.personId;
    }
  }
  return corporateClients || [];
}

export const validateEmail = (email) => {
  if(!email){
    return false;
  }
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}

export const encryptUrlParams = (params) => {
  try {
    if (!params) return "";
    const encryptedParams = CryptoJS.AES.encrypt(JSON.stringify(params), "url resure encryption").toString();
    return encodeURIComponent(encryptedParams); 
  } catch (error) {
    console.error("Error encrypting URL parameters:", error);
    return ""; 
  }
};

export const decryptUrlParams = (encryptedParams) => {
  try {
    if (!encryptedParams) return null;
    let decodedParams = decodeURIComponent(encryptedParams);
    if (/%[0-9A-Fa-f]{2}/.test(decodedParams)) {
      decodedParams = decodeURIComponent(decodedParams);
    }
    const decryptedBytes = CryptoJS.AES.decrypt(decodedParams, "url resure encryption");
    const decryptedParams = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedParams);
  } catch (error) {
    console.error("Error decrypting URL parameters:", error);
    return null;
  }
};

export const parseValue = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};
export const formatArrayContents = (values = []) => {
  try {
    parseValue(values);
    return Array.isArray(values) ? values.join(", ") : values ? values : "N/A";
  } catch (error) {
    return "N/A";
  }
};

export const getStreetAddress = (fullAddress) => {
  try {
   if (!fullAddress || typeof fullAddress !== "string") {
     return "";
   }
   const addressParts = fullAddress.split(",").map(part => part?.trim());
   const streetAddressParts = addressParts.slice(0, -2);
   return streetAddressParts.join(", ");
  } catch (error) {
   return "";
  }
};
export const buildPropertyFullAddress = (addressObj) => {
  if (!addressObj) return "";
  return `${addressObj.StreetNumber || ""}${addressObj.DirPrefix ? ` ${addressObj.DirPrefix}` : ""}${addressObj.StreetName ? ` ${addressObj.StreetName}` : ""}${addressObj.streetSuffix ? ` ${addressObj.streetSuffix}` : ""}${addressObj.DirSuffix ? ` ${addressObj.DirSuffix}` : ""}${addressObj.Unit ? `, ${addressObj.Unit}` : ""}, ${addressObj.City}, ${addressObj.State} ${addressObj.ZipCode}`;
};
