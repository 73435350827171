import React from "react";
import { Result, Button } from "antd";
import styles from "../style/Error.module.css";
import { shallowEqual, useSelector } from "react-redux";
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

function SomeThingWentWrong({ message, Back, status }) {
  const { currentUrlId } = useSelector(
    (state) => state.createOffer,
    shallowEqual
  );

  let redirectFlag = JSON.parse(localStorage.getItem("redirectTo")) ?? "webApp";
  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
  };
  let handleRedirect = () => {
    if (redirectFlag === "webApp") {
      window.location = apiURL;
      // window.history.back();
    } else {
      redirectHome();
    }
  };
  return (
    <Result
      status="500"
      title={
        <p className={styles.errorMessage}>
          {message || "We encountered an issue.Please try again later!"}{" "}
        </p>
      }
      subTitle={status ? <h3> {status}</h3> : <h3>500</h3>}
      extra={
        <Button onClick={handleRedirect} type="primary">
          Back
        </Button>
      }
    />
  );
}

export { SomeThingWentWrong };
