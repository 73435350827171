import React, { useState } from 'react';
import styles from './styles/CustomCalendar.module.css';
import moment from 'moment';
import AllDayEventsModal from './components/AllDayEventsModal';
import EventDetailModal from './components/EventDetailModal';
import { CaretRightOutlined, CaretLeftOutlined, PrinterOutlined, ShareAltOutlined } from "@ant-design/icons";
import { calendarHandle } from './state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Radio, Spin } from 'antd';
import { SignableAndReadOnly } from '../Common/components/SignableAndReadOnly';

function CustomCalendar({ events, source, documentId, calendarDataLoading, isInWebView, calendarDataEncrypt , shareCalendarLoading}) {
  const today = moment();
  const [dateState, setDateState] = useState({
    currentMonth: today.month(),
    currentYear: today.year(),
  });
  const [viewMode, setViewMode] = useState("COMPACT");
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  // Filter events based on type "CONTRACT_REMINDER" for transaction calendar
  const filteredEvents = source === 'TRANSACTION_CALENDAR' 
  ? events?.filter(event => event?.type === 'CONTRACT_REMINDER') 
  : events;


  let startMonth, endMonth, startYear, endYear;

  // Determine the month range based on the source type
  if (source === 'TRANSACTION_CALENDAR') {
    // Find the lowest and highest eventMonth
    const years = filteredEvents?.map(event => Number(event?.eventYear));
    startYear = Math.min(...years);
    endYear = Math.max(...years);

    if (startYear === endYear) {
      const months = filteredEvents?.map(event => Number(event?.eventMonth) - 1);
      startMonth = Math.min(...months);
      endMonth = Math.max(...months);
    } else {
      const monthsOfStartYear = filteredEvents
        .filter(event => Number(event.eventYear) === startYear)
        .map(event => Number(event.eventMonth) - 1); // Convert months to 0-indexed
      startMonth = Math.min(...monthsOfStartYear);  // Get the earliest month in startYear

      // Get months of events in the endYear
      const monthsOfEndYear = filteredEvents
        .filter(event => Number(event.eventYear) === endYear)
        .map(event => Number(event.eventMonth) - 1); // Convert months to 0-indexed
      endMonth = Math.max(...monthsOfEndYear);
    }
  } else if (source === 'HOME_CALENDAR') {
    // Show only one month for HOME_CALENDAR
    startMonth = dateState?.currentMonth;
    endMonth = dateState?.currentMonth;
    startYear = dateState?.currentYear;
    endYear = dateState?.currentYear;
  } else {
    // Default to 12 months for other sources
    startMonth = 0; // January
    endMonth = 11;  // December
  }

  // Filter events based on the date range
  // const allEventsOnRange = events.filter(event => {
  //   const eventMonth = Number(event.eventMonth) - 1; // Correcting for zero-indexed months
  //   return eventMonth >= startMonth && eventMonth <= endMonth;
  // });

  // Create calendars for the range of months
  const calendars = [];
  let diff;
  if (startYear === endYear) {
    // If the startYear and endYear are the same, the difference is just between the months
    diff = endMonth - startMonth;
  } else {
    // If the startYear and endYear are different, calculate based on multiple years
    const monthsInStartYear = 11 - startMonth; // Months remaining in the startYear from startMonth to December
    const monthsInEndYear = endMonth + 1; // Months from January to endMonth (inclusive) in endYear
    const fullYearsInBetween = (endYear - startYear - 1) * 12; // Full years between startYear and endYear (if any)

    // The total diff is the sum of months in the start year, months in the end year, and full years in between
    diff = monthsInStartYear + monthsInEndYear + fullYearsInBetween;
  }

  // Check if diff is Infinity or -Infinity and set it to 1 if so
  if (!isFinite(diff)) {
    diff = 1;
    startMonth = dateState?.currentMonth;
    endMonth = dateState?.currentMonth;
    startYear = dateState?.currentYear;
    endYear = dateState?.currentYear;
  }

  for (let i = 0; i <= diff; i++) {

    let month = (startMonth + i) % 12;
    let year = startYear + Math.floor((startMonth + i) / 12);

    calendars.push(
      <Calendar
        key={month}
        month={month}
        year={year}
        events={
          source === "TRANSACTION_CALENDAR" ? events?.filter(event => Number(event.eventMonth) - 1 === month && Number(event.eventYear) === year && event?.type === 'CONTRACT_REMINDER')
            : source === "HOME_CALENDAR" ?
              events?.filter(event => {
                const eventMonth = event?.eventMonth ? Number(event.eventMonth) - 1 : moment(event.startTimestamp).month();
                const eventYear = event?.eventYear ? Number(event.eventYear) : moment(event.startTimestamp).year();
                return eventMonth === month && eventYear === year;
              })
              : events
        }
        currentMonth={dateState?.currentMonth}
        setDateState={setDateState}
        source={source}
        documentId={documentId}
        calendarDataLoading={calendarDataLoading}
        viewMode={viewMode}
        isInWebView={isInWebView}
        calendarDataEncrypt={calendarDataEncrypt}
      />
    );
  }

  const handlePrint = () => {
    window.print();
  };

  const onViewChange = (e) => {
    setViewMode(e?.target?.value)
  }

  const handleShare = () => {
    setIsEmailModalVisible(true)
  }

  return (
    <div style={{backgroundColor: "white", position: "relative"}} className={styles.calendarWrapperContainer}>
      <div className={styles.viewModeOptions}>
        <Radio.Group className={styles.hideOnPrint} onChange={onViewChange} value={viewMode}>
          <Radio value={"COMPACT"}>Compact view</Radio>
          <Radio value={"EXPANDED"}>Expanded view</Radio>
        </Radio.Group>
        {!isInWebView && <Button className={styles.printBtn} size='small' onClick={handlePrint}><PrinterOutlined />Print</Button>}
        {(!calendarDataEncrypt?.screen || !["CLIENT_SCREEN", "ReadOnlyClientScreen"].includes(calendarDataEncrypt?.screen)) && <Button className={`${styles.shareBtn} ${isInWebView ? styles.shareBtnInWebView : ''}`} size='small' onClick={handleShare}>{shareCalendarLoading && <span><Spin size='small'/></span>}<ShareAltOutlined />Share</Button>}
      </div>
      <div className={styles.calendarWrapper}>
        <div className={styles.printTitleAndPropertyAddressCont}>
          <h2>CALENDAR</h2>
          {source === "TRANSACTION_CALENDAR" && filteredEvents?.[0]?.address?.fullAddress && <h2>{filteredEvents?.[0]?.address?.fullAddress}</h2>}
        </div>
        {calendars}
      </div>
      
      {/* Open send modal for sharing the calender to the clients */}
      {(isEmailModalVisible) &&
        <SignableAndReadOnly
          source={source}
          isEmailModalVisible={isEmailModalVisible}
          userAuthKey={calendarDataEncrypt?.key}
          setIsEmailModalVisible={setIsEmailModalVisible}
          offerId={calendarDataEncrypt?.offerId}
          rootDocData={calendarDataEncrypt}
          propertyAddress={filteredEvents?.[0]?.address?.fullAddress}
        />
      }
    </div>
  );
}

export default CustomCalendar;

const Calendar = ({ month, year, events, source, currentMonth, setDateState, documentId, calendarDataLoading, viewMode, isInWebView, calendarDataEncrypt }) => {
  const [modalStates, setModalStates] = useState({
    openDetailModal: false,
    openAllEventsModal: false,
    dayEvents: [],
    event: {}
  });
  const daysInMonth = moment({ year, month }).daysInMonth();
  const firstDay = moment({ year, month, day: 1 }).day();
  const days = [...Array(daysInMonth).keys()].map(i => i + 1);
  const today = moment();
  const dispatch = useDispatch();

  // Handle next month navigation
  const handleNextMonth = () => {
    const nextMonth = moment().year(year).month(currentMonth).add(1, 'month'); // Add 1 month
    const nextMonthTimestamp = nextMonth.valueOf(); // Get the timestamp for the next month
    setDateState(prev => ({
      ...prev,
      currentMonth: (prev.currentMonth + 1) % 12, // Increment currentMonth, wrap around to 0 after December
      currentYear: prev.currentMonth === 11 ? prev.currentYear + 1 : prev.currentYear // Increment year if month wraps to 0
    }));

    // setDateState(prev => ({...prev, currentMonth: (currentMonth + 1) % 12})); // Increment currentMonth by 1
    dispatch(calendarHandle.getCalendarData({ id: documentId, source, payloadTimestamp: nextMonthTimestamp }));
  };

  // Handle previous month navigation
  const handlePrevMonth = () => {
    const prevMonth = moment().year(year).month(currentMonth).subtract(1, 'month'); // Subtract 1 month
    const prevMonthTimestamp = prevMonth.valueOf(); // Get the timestamp for the previous month
    setDateState(prev => ({
      ...prev,
      currentMonth: prev.currentMonth === 0 ? 11 : prev.currentMonth - 1, // Decrement currentMonth, wrap around to 11 after January
      currentYear: prev.currentMonth === 0 ? prev.currentYear - 1 : prev.currentYear // Decrement year if month wraps to 11
    }));
    // setDateState(prev => ({...prev, currentMonth: currentMonth === 0 ? 11 : prev - 1})); // Increment currentMonth by 1
    dispatch(calendarHandle.getCalendarData({ id: documentId, source, payloadTimestamp: prevMonthTimestamp }));
  };


  // Create a grid for the days
  const grid = [];
  for (let i = 0; i < firstDay; i++) {
    grid.push(<div className={styles.empty} key={`empty-${i}`} />);
  }
  for (let day of days) {
    const dateString = moment({ year, month, day }).format('MM/DD/YYYY');
    const dayEvents = events.filter(event => moment(event.eventDate || event.startTimestamp).date() === day);

    grid.push(
      <div
        className={`${styles.day} ${today.isSame(moment({ year, month, day }), 'day') ? styles.today : ''}`}
        key={day}
        data-date={dateString}
      >
        <div className={styles.dayNumber}>{day}</div>
        <div className={styles.eventContainer}>
          {dayEvents?.map((event, index) => {
            const now = moment();
            const dateOrDeadline = moment(event?.dateOrDeadline);
            const completedDateMili = event?.completedDateMili ? moment(event?.completedDateMili) : null;

            let incompleteEvent = false;
            let completedEvent = false;
            let upcomingEvent = false;

            if (event?.type === "SCHEDULE" || event?.type === "REMINDER") {
              const startTimestamp = moment(event?.startTimestamp);
              const endTimestamp = moment(event?.endTimestamp);
              if (endTimestamp?.isBefore(now)) {
                completedEvent = true;
              } else if (startTimestamp?.isBefore(now)) {
                upcomingEvent = true;
              }
            } else {
              if (dateOrDeadline.isBefore(now) && !completedDateMili) {
                // Deadline passed and task not completed
                incompleteEvent = true;
              } else if (completedDateMili && completedDateMili.isBefore(now)) {
                // Task completed
                completedEvent = true;
              } else if (dateOrDeadline.isAfter(now) && !completedDateMili) {
                // Deadline not passed and task not completed
                upcomingEvent = true;
              }
            }

            return <div key={index}
              className={`${styles.eventTitle} 
            ${(event?.eventType === "PUBLIC HOLIDAY") ? styles.holiday :
                  event?.title === "Closing Date" ? styles.closingDate :
                  completedEvent ? styles.completedEvent :
                  incompleteEvent ? styles.incompleteEvent :
                  upcomingEvent ? styles.upcomingEvent : ""}`}
              onClick={() => setModalStates({ event, openDetailModal: true })}
              style={viewMode === "EXPANDED" ? {
                lineHeight: "normal",
                overflow: "unset",
                whiteSpace: "normal",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                textOverflow: "unset",
              } : {}}
            >
              {event.title}
            </div>
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.calendar} style={isInWebView || (calendarDataEncrypt?.screen === "CLIENT_SCREEN") ? {width: "100%"} : {}}>
        <div className={styles.header}>
          {source === "HOME_CALENDAR" ?
            <div className={styles.headerTools}>
              <span className={styles.arrow} onClick={handlePrevMonth}><CaretLeftOutlined /></span>
              <span className={styles.monthTitle}>{moment({ year, month: currentMonth }).format('MMMM YYYY')}</span>
              <span className={styles.arrow} onClick={handleNextMonth}><CaretRightOutlined /></span>
            </div> :
            moment({ year, month }).format('MMMM YYYY')
          }
        </div>
        {/* {calendarDataLoading ? <div><Spin/></div> : 
        calendarData ?
        <> */}
        <div className={styles.weekdays}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div className={styles.weekday} key={day}>{day}</div>
          ))}
        </div>
        <div className={styles.daysGrid}>{grid}</div>
        {/* </> : null} */}
      </div>

      {modalStates?.openAllEventsModal && <AllDayEventsModal setModalStates={setModalStates} modalStates={modalStates} />}
      {modalStates?.openDetailModal && <EventDetailModal setModalStates={setModalStates} modalStates={modalStates} />}
    </>
  );
};
