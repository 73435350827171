/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from "react";
import { OfferTableRender } from "./OfferTableRender";
import { useParams } from "react-router-dom";
import { offerTableHandle } from "./state/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip, Image, Dropdown, notification, message } from "antd";
import { InfoCircleOutlined, MoreOutlined, CloseOutlined, CloudUploadOutlined ,CaretUpOutlined,CaretDownOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import styles from "./styles/OfferTable.module.css";
import housePlaceHolder from "../Assets/Icons/imagePlaceholder.webp";
import { useHistory } from "react-router-dom";
import { SomeThingWentWrong } from "../Common/components/SomeThingWentWrong";
import { LegendData} from "./features/helperFuntions";
import { MenuItem } from "./components/MenuItem";
// import { EmailModal } from "./components/EmailModal";
import DraggableModal from "./components/DraggableModal";
import dotEnv from "dotenv";
import { detectMobile, detectWebView, getValuesFromCookies} from "../Common/utils/universalFuntions";
import { TransactionLane } from "../Rtd/TransactionLane";
import { Route, Switch } from "react-router-dom";
import { Help } from "../App/components/Help";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { HiArrowLeft } from "react-icons/hi2";
import { io } from "socket.io-client";
import OfferImport from "./components/OfferImport";
import offerUpdate from "../Assets/Icons/offerupdate.png"
import Header from "./components/Header";
import "./styles/License.css";
import confirm from "antd/lib/modal/confirm";
import { SignableAndReadOnly } from "../Common/components/SignableAndReadOnly";
import { OfferImportModal } from "./components/OfferImportModal";
import { commonDataHandle } from "../Common/state/actions";
import { disabledTransactionText } from "../Common/commondata/commonData";
dotEnv.config();
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

function OfferTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  // const socketRef = useRef(null);
  const { id } = useParams();
  useEffect(() => {
    dispatch(offerTableHandle.URLShorten(id));
  }, [dispatch, id]);

  const { offerTablePropertyId, offerTableAuthKey, OTAddCriteriaKeysArray } = useSelector((state) => state.offerTable);
  const [sort, setSort] = useState("PRICE");
  const [order, setOrder] = useState("DEC");
  const socketRef = useRef(null);

  useEffect(() => {
    let currentSocket = socketRef.current;
    if (offerTablePropertyId && !currentSocket) {
      currentSocket = io(`${process.env.REACT_APP_SOCKET_BASE_URL}`);
      currentSocket?.emit("property_connected", { propertyId: String(offerTablePropertyId)});
      let currentNotificationKey = null;
      currentSocket.on("common_event_admin", (eventData) => {
        const { offerId, message } = eventData;
        // console.log("confirmationMessage", message);
        if (currentNotificationKey) {
          notification.close(currentNotificationKey);
        }
        currentNotificationKey = `common_event_admin_${Date.now()}`;
        notification.open({
          message: message || "",
          description: "Do you want to update the content with the latest data?",
          icon: <Image src={offerUpdate} preview={false} width={"40"} height={"30"} style={{ marginLeft: "-8px" }} alt="" />,
          duration: 0,
          btn: (
            <div style={{ display: "flex", gap: "10px", margin: "10px 10px" }}>
              <Button
                onClick={() => {
                  notification.close(currentNotificationKey);
                }}
              >
                No
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  let ordernew = order ? order : "ASC";
                  let sortnew = sort ? sort : "PRICE";
                  history.push(`/offer/${id}?offer=${offerId}`);
                  dispatch(offerTableHandle.getOfferTable(offerTableAuthKey, offerTablePropertyId, sortnew, ordernew));
                  notification.close(currentNotificationKey);
                }}
              >
                Yes
              </Button>
            </div>
          ),
          key: currentNotificationKey,
        });
      });
    }
    return () => {
      if (currentSocket) {
        currentSocket.disconnect();
      }
    };
  }, [offerTablePropertyId]);

  useEffect(() => {
    if (offerTableAuthKey && offerTablePropertyId) {
      dispatch(offerTableHandle.getRestrictedClientsOfferTableToClients(offerTableAuthKey, offerTablePropertyId));
      if (!OTAddCriteriaKeysArray) {
        dispatch(offerTableHandle.getConstantsFun(offerTableAuthKey, { constantType: "OFFER_TABLE_CRITERIA" }));
      }
    }
  }, [offerTableAuthKey]);


  const { getEmail_TemplateData, offerTableData } = useSelector((state) => state.offerTable);
  useEffect(() => {
    if ((!getEmail_TemplateData || getEmail_TemplateData?.length === 0) && offerTableAuthKey) {
      dispatch(offerTableHandle.getEmail_Template(offerTableAuthKey));
    }
  }, [getEmail_TemplateData?.length, offerTableAuthKey]);

  useEffect(() => {

    if (offerTablePropertyId !== null && offerTableAuthKey ) {

      dispatch(offerTableHandle.getOfferTable(offerTableAuthKey, offerTablePropertyId, "PRICE", "DEC"));
    }
    return () => {};
  }, [dispatch, offerTablePropertyId]);

  const [showhidden, setShowhidden] = useState(false);

  const handleHiddenOffers = () => {
      const hasHiddenElement = offerTableData.some((element) => element.markedAsHidden);
      if (hasHiddenElement||showhidden) {
          setShowhidden(!showhidden);
        }
        else if(!hasHiddenElement&&!showhidden){
            message.error("No hidden offers available")
        }
    };

  return (
    <>
      {/* legend Modal */}
      <Switch>
        <Route path="/offer/:id/rtd/:offerId" component={TransactionLane} />

        <Route path="/offer/:id" component={() => <NewOfferTable showhidden={showhidden} setShowhidden={setShowhidden} handleHiddenOffers={handleHiddenOffers} sort={sort} order={order} setSort={setSort} setOrder={setOrder} />} />
      </Switch>
    </>
  );
}

function NewOfferTable({ showhidden, handleHiddenOffers, sort, order, setOrder, setSort,setShowhidden }) {
  const [showLegend, setShowLegend] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [offerImportModalVisible, setOfferImportModalVisible] = useState(false);
  const { offerTablePropertyId, totalOffers, URLofferTableJSON, error, offerTableAuthKey,  propertySellers, offerTableloading, offerTableCustomLoading, checkForDisable} = useSelector((state) => state.offerTable);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location?.search);
  const tabId = query?.get("tabId");
  const [isMenuOpen,setisMenuopen]=useState(true);
  const currentSourceRef = useRef("SHARE_OMD");

  const handleOmdMenu = (event) => {
    switch (event?.key) {
      case 'SHARE_OMD':
        handleShareOMD();
        break;
      case 'SEND_EMAIL_BUYER_AGENT':
        handleSendEmailToBuyerAgent();
        break;
      case 'HIDDEN_OFFERS':
        handleHiddenOffers();
        break;
      default:
        break;
    }
  };

  const handleShareOMD = () => {
    setIsEmailModalVisible(true);
    setisMenuopen(false);
    currentSourceRef.current = "SHARE_OMD";
    dispatch(commonDataHandle.getEmailSignData(offerTableAuthKey));
  };

  const handleSendEmailToBuyerAgent = () => {
    setIsEmailModalVisible(true);
    setisMenuopen(false);
    currentSourceRef.current = "SEND_EMAIL_BUYER_AGENT";
    dispatch(commonDataHandle.getEmailSignData(offerTableAuthKey));
  };

  const handleRestrict = (el, source) => {
    let payload = {
      clientId: el.key,
      propertyId: offerTablePropertyId,
    };
    confirm({
      title: source === "RESTRICT" ? `Are you sure you want to revoke access for ${el.name} to view the Offer Management Dashboard?` : `Are you sure you want to grant access to ${el.name} to view the Offer Management Dashboard?`,
      content: `Note: You can update the access any time you want.`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(offerTableHandle.restrictOfferTableToClients(offerTableAuthKey, payload, offerTablePropertyId));
      },
      onCancel() {},
    });
  };
  const location = useLocation();
  useEffect(() => {
    if ( URLofferTableJSON?.urlData?.sharedIndividualOffer) {
      if (!location?.pathname?.match(/\/offer\/\w+\/rtd\/\w+/)) {
        history.push("/error");
      }
    }
  }, [URLofferTableJSON]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = async () => {
    localStorage.removeItem(id);
    let redirectHome = async () => {
      await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
    };
    const isInWebView = detectWebView();
    if (URLofferTableJSON?.urlData?.sellerOpenOfferTable) {
      history.push(`/success?client=true`);
    } else {
      if (isInWebView) {
        redirectHome();
      } else {
        const origin = await getValuesFromCookies(tabId);
        if (origin) {
          window.location.href = `${apiURL}${origin}?category=SELLER&categoryId=${offerTablePropertyId}`;
        } else {
          if (URLofferTableJSON?.isTransCoDashboard) {
            window.location.href = `${apiURL}/account/delegates/transactions?category=SELLER&categoryId=${offerTablePropertyId}`;
          } else {
            window.location.href = `${apiURL}/transactions?category=SELLER&categoryId=${offerTablePropertyId}`;
          }
        }
      }
      return;
    }
  };
  const [isMobiletrue,setIsmobiletrue]=useState(false)
  useEffect(() => {
    const fetchData = async () => {
      const isMobile=await detectMobile()
      setIsmobiletrue(isMobile)
    };

    fetchData(); // Call the function when the component mounts

  }, []);


  return (
    <>
      <OfferImportModal offerImportModalVisible={offerImportModalVisible} setOfferImportModalVisible={setOfferImportModalVisible} />
      <DraggableModal
        visible={showLegend}
        okText="Save"
        cancelText="Cancel"
        footer={false}
        closeIcon={
          <div style={{ position: "absolute", top: "0", left: "0", right: "0" }}>
            <CloseOutlined style={{ color: "white", borderRadius: "50%", fontSize: "15px", fontWeight: "bold", padding: "5px", marginTop: "30px", marginRight: "15px" }} />
          </div>
        }
        onCancel={() => setShowLegend(false)}
        onOk={() => {}}
      >
        {LegendData?.length > 0 ? (
          <table className={styles.licenseTable}>
            <thead>
              <tr>
                <th style={{ borderRight: "1px solid white" }}>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {LegendData.map((el) => (
                <tr key={el.id}>
                  <td
                    style={{
                      fontWeight: 600,
                      width: "20%",
                      border: "1px solid lightgray",
                    }}
                  >
                    <Button style={{ textAlign: "center", color: el.color, border: el.border, background: el.backgroundColor, width: "90%", borderRadius: "5px" }}> {el.status}</Button>
                  </td>
                  <td
                    style={{
                      fontWeight: "500",
                      width: "70%",
                      borderRight: "1px solid lightgray",
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    {el.info}
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "none" }}>
                  {" "}
                  <div
                    style={{
                      display: "block",
                      width: "10%",
                      marginTop: "20px",
                    }}
                  >
                    {/* console.log  */}
                    <CaretUpOutlined
                      style={{
                        color: "hsl(209, 85%, 39%)",
                        fontSize: "20px",
                      }}
                    />
                    <CaretDownOutlined
                      style={{
                        color: "hsl(209, 85%, 39%)",
                        fontSize: "20px",
                      }}
                    />
                  </div>
                </td>
                <td
                  style={{
                    fontWeight: "500",
                    width: "70%",
                    borderRight: "1px solid lightgray",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  If the field is sorted from lowest to highest, the up arrow turns orange; if sorted from highest to lowest, the down arrow turns orange, indicating the sorting direction.
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
      </DraggableModal>
      <SignableAndReadOnly
        source={currentSourceRef?.current}
        isEmailModalVisible={isEmailModalVisible}
        setIsEmailModalVisible={setIsEmailModalVisible}
        selectedData={propertySellers}
        setisMenuopen={setisMenuopen}
        //  handleRestrict={handleRestrict}
        // restrictedClientData={restrictedClientData}
      />
      {error ? (
        <SomeThingWentWrong message={error.message} status={error.statusCode} />
      ) : (
        <div>
          <Header urlId={id} />
          {
            (offerTableloading || offerTableCustomLoading) ? null : <nav className={styles.offerTableNavbar}>
              <section className={styles.navbarSection1}>
                <div className={styles.backButton} onClick={handleClose}>
                  <HiArrowLeft
                    style={{
                      fontSize: "26px",
                    }}
                  />
                  {window.screen.width > 850 ? <div style={{ fontSize: "1rem", marginTop: "-10px", color: "#4287D1" }}>Back</div> : null}
                </div>

                <div style={{ maxWidth: "135px" }}>
                  <Image src={URLofferTableJSON?.propertyImage ? URLofferTableJSON?.propertyImage : housePlaceHolder} alt="" className={styles.propertyimage} width={window.screen.width > 950 ? "100px" : "70px"} height={window.screen.width > 950 ? "65px" : window.screen.width > 550 ? "50px" : "60px"} />
                </div>
                <div className={styles.propertybox}>
                  <div className={styles.prorertyAddress}>
                    <Tooltip title={URLofferTableJSON?.fullAddress} placement="rightTop">
                      {URLofferTableJSON && URLofferTableJSON.fullAddress}
                    </Tooltip>
                  </div>
                  <div className={styles.offerNumber}>Total Offers : {totalOffers}</div>
                </div>
              </section>
              <section className={styles.navbarSection2} style={{ paddingTop: "10px", marginLeft: "-20px", display: "flex", gap: "20px", alignItems: "center", border: "" }}>
                <div className={styles.flex}>
                  <Tooltip title="Legend" placement="top">
                    <InfoCircleOutlined onClick={() => setShowLegend(true)} className={styles.legend} style={{ color: "hsl(209, 85%, 39%" }} />
                  </Tooltip>

                  <span className={styles.legendtext} onClick={() => setShowLegend(true)} style={{ color: "hsl(209, 85%, 39%" }}>
                    Legend
                  </span>
                </div>
                <OfferImport isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

                <div
                  className={styles.flex}
                  {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => !URLofferTableJSON?.urlData?.sellerOpenOfferTable && setOfferImportModalVisible(true)})}
                >
                  <Tooltip placement="top" title={URLofferTableJSON?.urlData?.sellerOpenOfferTable? "" : "Import offer from PDF document"}>
                    <CloudUploadOutlined
                      className={styles.import}
                      style={{
                        color: (URLofferTableJSON?.urlData?.sellerOpenOfferTable )? "lightgray" : "hsl(209, 85%, 39%)",
                        cursor: (URLofferTableJSON?.urlData?.sellerOpenOfferTable ) ? "not-allowed" : "pointer",
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={URLofferTableJSON?.urlData?.sellerOpenOfferTable? "" : "Import offer from PDF document"}>
                    <div
                      className={styles.legendtext}
                      style={{
                        color: (URLofferTableJSON?.urlData?.sellerOpenOfferTable ) ? "lightgray" : "hsl(209, 85%, 39%)",
                        cursor: (URLofferTableJSON?.urlData?.sellerOpenOfferTable ) ? "not-allowed" : "pointer",
                      }}
                    >
                      Import
                    </div>
                  </Tooltip>
                </div>

                <div className={styles.helpicon}>
                  <Help section={"OFFER_TABLE"} offerTableAuthKey={offerTableAuthKey} />
                </div>
                <div className={styles.menuIcon}>
                  {URLofferTableJSON?.urlData?.sellerOpenOfferTable ? null : (
                    <Dropdown trigger={["click", "hover"]} overlay={<MenuItem isMenuOpen={isMenuOpen} showhidden={showhidden} handleOmdMenu={handleOmdMenu}/>}>
                      <MoreOutlined
                        key="ellipsis"
                        style={{
                          color: "black",
                          angle: "90 deg",
                        }}
                      />
                    </Dropdown>
                  )}
                </div>
              </section>
            </nav>
          }
          
          <main style={{ background: "rgb(251, 249, 249)", zIndex: "4", overflowY: "hidden" }} className={isMobiletrue ? styles.offertable : null}>
            <OfferTableRender showhidden={showhidden} setisMenuopen={setisMenuopen} setShowhidden={setShowhidden} showLegend={showLegend} offerTablePropertyId={offerTablePropertyId} URLofferTableJSON={URLofferTableJSON} sort={sort} order={order} setOrder={setOrder} setSort={setSort} />
          </main>
        </div>
      )}
    </>
  );
}

export { OfferTable };
