import { Button, Col, Form, Input, Modal, Row } from 'antd'
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminAction } from '../state/actions';
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { firmDetailsHandle } from '../../FirmCreation/state/action';
import FranchiseList from './FranchiseList';


function BrokerageDetails({ setBrokerageInput, brokerageInput, officeInput, setOfficeInput, currentSelectedFranchise, setCurrentSelectedFranchise }) {
    const dispatch = useDispatch();
    const { getBrokrageError,
        getBrokrageLoading,
        getBrokrageData,
        getFranchiseData,
        getOfficeLoading,
        getOfficeError,
        getFranchiseError,
        getFranchiseLoading,
    } = useSelector((state) => state.firmDetails);

    const brokerageHandle = useRef(false);
    const currentTag = useRef("BROKERAGE");
    const [searchText, setSearchText] = useState("");
    const [selectedData, setSelectedData] = useState({});
    const [dataSource, setDataSource] = useState(getFranchiseData);
    const [searchFirmText, setSearchFirmText] = useState("");
    const [showListModal, setShowListModal] = useState(false);
    const officeHandle = useRef(false);
    const officeShow = useRef(false);
    const [error, setError] = useState({
        franchiseStatus: false,
        brokerageStatus: false,
        officeStatus: false,
        message: "",
    });

    const handleOfficeDetails = () => {
        let query = {};

        if (currentTag.current === "BROKERAGE") {
            if (searchText) {
                query = {
                    officeTypes: "BROKERAGE",
                    ...(selectedData?._id ? { parentId: selectedData._id } : {}),
                    search: searchText
                }
            } else {
                query = {
                    officeTypes: "BROKERAGE",
                    ...(selectedData?._id ? { parentId: selectedData._id } : {}),
                }
            }
            dispatch(firmDetailsHandle.getBrokrage(query));
            setDataSource(getBrokrageData)
        }
        else if (currentTag.current === "OFFICE") {
            if (searchText) {
                query = {
                    officeTypes: "OFFICE",
                    ...(selectedData?._id ? { parentId: selectedData._id } : {}),
                    search: searchText
                }
            } else {
                query = {
                    officeTypes: "OFFICE",
                    ...(selectedData?._id ? { parentId: selectedData._id } : {}),
                }
            }
            dispatch(firmDetailsHandle.getAllFranchise(query));
            setDataSource(getFranchiseData)
        }
    }

    // when user selects any firms
    const handleFranchiseSelected = (selectedFranchise) => {
        setSelectedData(selectedFranchise)
        let query = {}

        if (currentTag.current === "BROKERAGE") {
            if (searchText) {
                query = {
                    officeTypes: "BROKERAGE",
                    parentId: selectedFranchise._id,
                    search: searchText
                }
            } else {
                query = {
                    officeTypes: "BROKERAGE",
                    parentId: selectedFranchise._id,
                }
            }
            setBrokerageInput(selectedFranchise.name);
            officeShow.current = true;
            brokerageHandle.current = true;
            currentTag.current = "OFFICE";
            setSearchText("");
            setError({ ...error, brokerageStatus: false, message: "" });
        } else if (currentTag.current === "OFFICE") {
            if (searchText) {
                query = {
                    officeTypes: "OFFICE",
                    parentId: selectedFranchise._id,
                    search: searchText
                }
            } else {
                query = {
                    officeTypes: "OFFICE",
                    parentId: selectedFranchise._id,

                }
            }
            setDataSource(getFranchiseData);
            setOfficeInput(selectedFranchise.name);
            setError({ ...error, officeStatus: false, message: "" });
            officeHandle.current = true;
            setCurrentSelectedFranchise(selectedFranchise);
            setSearchText("");
        }
        setSearchFirmText("")
        setShowListModal(false);
    };

    const handleSubmit = () => {
        if (!brokerageInput) {
            setError({
                ...error,
                brokerageStatus: true,
                message: "Please Select Brokerage!",
            });
            return;
        }
        if (!officeInput) {
            setError({
                ...error,
                officeStatus: true,
                message: "Please Select  Office!",
            });
            return;
        }
        let payload = {
            officeId: currentSelectedFranchise._id,
        };
        dispatch(adminAction.openBrokerageSelectModal(false))
        setSelectedData({})
    };

    const handleCancelBrokerageModal = () => {
        setCurrentSelectedFranchise("");
        setBrokerageInput("");
        setOfficeInput("");
        dispatch(adminAction.openBrokerageSelectModal(false))
    }

    return (
        <div>
            <Modal
                visible={true}
                closable
                footer={null}
                onCancel={handleCancelBrokerageModal}
                title={<div style={{ textAlign: 'center', color: '#178DFA' }}>FIRM DETAILS</div>}
                mask={false}
            >
                <Form labelAlign='left' labelCol={{ span: 6 }}>
                    <Form.Item
                        label="Brokerage"
                        name="brokerageId"
                        rules={[{ required: true, message: "Please Select Brokerage." }]}
                    >
                        <Row gutter={10}>
                            <Col span={20}>
                                <div style={{ display: "flex" }}>
                                    <Input
                                        allowClear
                                        value={brokerageInput}
                                        placeholder="Search a brokerage"
                                        disabled={brokerageHandle.current}
                                        onClick={() => {
                                            setDataSource(getBrokrageData);
                                            handleOfficeDetails()
                                            setSearchFirmText("")
                                            setShowListModal(true);
                                        }}
                                        onChange={(e) => {
                                            setBrokerageInput(e.target.value);
                                            setSearchText(e.target.value);
                                            if (e.target.value) {
                                                setError({
                                                    ...error,
                                                    brokerageStatus: false,
                                                    message: "",
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                            {!brokerageHandle.current && (
                                <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
                                    <Button
                                        onClick={() => {
                                            setDataSource(getBrokrageData);
                                            handleOfficeDetails()
                                            setSearchFirmText("")
                                            setShowListModal(true);
                                        }}
                                        style={{ padding: "0px 6px" }}
                                    >
                                        <SearchOutlined style={{ fontSize: "18px" }} />
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form.Item>
                    {officeShow.current && (
                        <Form.Item
                            label="Office Name"
                            name="officeId"
                            rules={[{ required: true, message: "" }]}
                        >
                            <Row gutter={10}>
                                <Col span={20} style={{ display: "flex" }}>
                                    <Input
                                        allowClear
                                        value={officeInput}
                                        placeholder="Search a Office"
                                        onClick={() => {
                                            setDataSource(getFranchiseData)
                                            handleOfficeDetails()
                                            setSearchFirmText("")
                                            setShowListModal(true);
                                        }}
                                        disabled={officeHandle.current}
                                        onChange={(e) => {
                                            setOfficeInput(e.target.value);
                                            setSearchText(e.target.value);
                                            if (e.target.value) {
                                                setError({
                                                    ...error,
                                                    officeStatus: false,
                                                    message: "",
                                                });
                                            }
                                        }}
                                    />
                                </Col>
                                {!officeHandle.current && (
                                    <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
                                        <Button
                                            onClick={() => {
                                                setDataSource(getFranchiseData)
                                                handleOfficeDetails()
                                                setSearchFirmText("")
                                                setShowListModal(true);
                                            }}
                                            style={{ padding: "0px 6px" }}
                                        >
                                            <SearchOutlined style={{ fontSize: "18px" }} />
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            {error.officeStatus ? (
                                <div style={{ color: "red" }}>{error.message}</div>
                            ) : (
                                ""
                            )}
                        </Form.Item>
                    )}

                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={handleSubmit} htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                visible={showListModal}
                onOk={() => { }}
                style={{ top: 20 }}
                onCancel={() => {
                    setSearchFirmText("")
                    setShowListModal(false)
                }}
                footer={false}
            >
                <FranchiseList
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    selectClient={handleFranchiseSelected}
                    currentTag={currentTag.current}
                    searchFranchiseText={searchText}
                    searchFirmText={searchFirmText}
                    setSearchFirmText={setSearchFirmText}
                />
            </Modal>
        </div>
    )
}

export default BrokerageDetails