import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const adminCredentialRequest = (data) => {
  return axios.post(`${apiURL}/admin/login`,
    {
      ...data,
    },
    {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }
  );
};

const signupRealtorRequest = (data) => {
  // console.log("registering from admin side Authenticate API");
  let accessToken = localStorage.getItem("auth");
  return axios.post(
    `${apiURL}/person/registerUserByAdmin`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${accessToken}`,
      }
    }
  );
};

// Forgot Password
const userForgetPasswordRequest = (data) => {
  let mail = { emailId: data.email };
  return axios.post(`${apiURL}/person/forgetPasswordInti`, mail, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};
const userForgetPasswordCodeVerificationRequest = (data, email) => {
  let code = { code: data, emailId: email };
  return axios.post(`${apiURL}/person/codeVerification`, code, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};
// Save new password in the backend
const resetPasswordSaveNewPasswordRequest = (data, email) => {
  let code = { password: data, emailId: email };
  return axios.post(`${apiURL}/person/passwordChange`, code, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};
export const adminDataApi = {
  adminCredentialRequest,
  signupRealtorRequest,
  userForgetPasswordRequest,
  userForgetPasswordCodeVerificationRequest,
  resetPasswordSaveNewPasswordRequest,
};
