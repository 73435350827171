/* eslint-disable react-hooks/exhaustive-deps */
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import '../style/customInput.css'; 
import { v4 as uuidv4 } from "uuid";
import { Button, Collapse, Form, Image, Input, message, Modal, Select, Spin, Tooltip } from "antd";
import { Checkbox } from "antd";
import EmailTemplateModal from "../../Common/components/EmailTemplateModal";
import ContentEditable from "../../Common/components/ContentEditable";
import AddEmail from "../../Common/components/AddEmail";
import { offerTableHandle } from "../../OfferTable/state/actions";
import { EyeOutlined, WarningOutlined, DeleteOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import DocumentList from "./DocumentList";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { TiContacts } from "react-icons/ti";
import { ClientList } from "../../CreateOffer/components/ClientList";
import { generateFullNameFromObj, getcombinedClients, mapClientsForSendEmail, validateEmail } from "../utils/universalFuntions";
import { calendarHandle } from "../../CalenderComponent/state/actions";
import RecipientList from "./RecipientList";
import { Loading } from "./Loading";
import styles from "../style/SendEmailModal.module.css"
import SubjectLine from "./SubjectLine";
import confirm from "antd/lib/modal/confirm";
const { Option } = Select;

// This is the new design in send document
const SendEmailModal = ({ role, newState, setNewState, initialMountRef, setShowTextArea, showTextArea, selectedData, isEmailModalVisible, documentId, setIsEmailModalVisible, currentPropertyDcoId, ispdfClone, source, isSmallScreen, isMediumScreen, nestedModalOpen, client, userAuthKey, RTDInitiater, contractType, offerId, rootDocData, allBuyerAgentData ,getTransactionLaneLoading, setCallPropertyDocsApi, loading, formattedId, propertyAddress}) => {
  const [clientsArray, setClientsArray] = useState([]);
  const [signerChecked, setSignerChecked] = useState({});
  const [agents, setAgents] = useState(false);
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [emailList, setEmailList] = useState({
    emailMessage: "",
  });
  const handleSetAgents=(value)=>{
  setAgents(value)
   }

  const [data, setData] = useState({
    documentSelected: [],
    propertyDocsSelected: [],
    sharedSelected: [],
    clientDocumentSelected: [],
    documentWithoutBuyerSelected: [],
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [subjectForm] = Form.useForm();
  const { addEmailLoading } = useSelector((state) => state.rtdTransactionLane);
  const { propertyDocs, propertDocsLoading, personDocs, personDocsLoading } = useSelector((state) => state.createOffer, shallowEqual);

  const mapClientDocs = (docsTabDoraDocs) => {
    let clientDocuments = [];
    docsTabDoraDocs?.forEach((doc) => {
      if (doc.createdDocuments?.length > 0) {
        doc.createdDocuments.forEach((subDoc) => {
          clientDocuments.push(subDoc);
          if (subDoc.matchedDocuments?.length > 0) {
            subDoc.matchedDocuments.forEach((matchDocument) => {
              clientDocuments.push(matchDocument);
            });
          }
        });
      }
    });

    return clientDocuments;
  };
  const clientDocs = mapClientDocs(personDocs);

  const [enterEmailModal, setEnterEmailModal] = useState({
    visible: false,
    data: {},
    inValid: false,
  });
  const breakPoint = useMediaQuery({ maxWidth: "573px" });

  const [clickedDataEmail, setClickedDataEmail] = useState({});
  const [Email_templatevisible, setEmail_templatevisible] = useState(false);
  const [emailSignatureId, setEmailSignatureId] = useState({});
  const [ispreview, setIspreview] = useState(false);
 const [selectedtext, setSelectedText] = useState("");
  const { offerTablePropertyId, offerTableAuthKey, offerTableData } = useSelector((state) => state.offerTable);
  const { emailSignData, emailSignLoading } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const defaultSignVal = emailSignData?.find((sign) => sign.isDefault === true) || { isDefault: false, _id: "" };
      setEmailSignatureId({ ...defaultSignVal });
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      setEmailSignatureId({ checked: false, value: "" });
    };
  }, [emailSignData]);

  const combinedClientsData = useMemo(() => {
    const data = getcombinedClients({
      allContractDocuments: personDocs?.length > 0 ? clientDocs : propertyDocs || [],
      condition: (source === 'TRANSACTION_CALENDAR') ? ((rootDocData?.openFor === 'BUYER_AGENT') ? 'BUYER' : (rootDocData?.openFor === 'SELLER_AGENT') ? 'SELLER' : rootDocData?.openFor) : role === "BUYER" ? "BUYER" : "SELLER",
    });

    return {
      combinedBuyerIds: data.combinedBuyerIds,
      combinedSellerIds: data.combinedSellerIds,
    };
  }, [propertyDocs, clientDocs, role]);



  useEffect(() => {
    let tempArr = [];
    switch (source) {
      case "SELLING_RTD":
        tempArr = mapClientsForSendEmail({ clients: combinedClientsData.combinedSellerIds, role: "SELLER",});
        break;
      case "CONTACT_SELLING_RTD":
        tempArr = mapClientsForSendEmail({ clients: combinedClientsData.combinedSellerIds, role: "SELLER", });
        break;
      case "CONTACT_BUYING_RTD":
        tempArr = mapClientsForSendEmail({ clients: combinedClientsData.combinedBuyerIds, role: "BUYER", });
        break;
      case "SHARE_INDIVIDUAL_OFFER":
        tempArr = mapClientsForSendEmail({ clients: selectedData?.sellerIds, role: "SELLER" });
        break;
      case "SHARE_OMD":
        tempArr = mapClientsForSendEmail({ clients: selectedData, role: "SELLER" });
        break;
      case "SEND_EMAIL_BUYER_AGENT":
        offerTableData?.forEach((data) => {
          const agents = (data?.buyerAgentIds?.length > 0) ? data?.buyerAgentIds : (data?.transactionData?.BuyerAgents || []);
          agents?.forEach((agent) => {
            const fullName = agent?.fullName || "";
            const email = agent?.email || "";
            const combinedId = (fullName?.toLowerCase().replace(/\s+/g, "") || "") + "-" + (email?.toLowerCase() || "");
            const buyerAgentId = agent?._id || combinedId;

            // Check if tempDuplicateKey already exists in tempArr
            const isDuplicate = tempArr?.some((item) => item?.tempDuplicateKey === combinedId);

            if (!isDuplicate) {
              tempArr.push({
                fullName: fullName,
                isCorp: false,
                openFor: "BUYER_AGENT",
                personId: {
                  email: email,
                  fullName: fullName,
                  _id: buyerAgentId,
                },
                signers: [],
                _id: buyerAgentId,
                tempDuplicateKey: combinedId,
              });
            }
          });
        });
        tempArr?.forEach((item) => delete item?.tempDuplicateKey);
        break;
      case "TRANSACTION_CALENDAR":
        let role = (rootDocData?.openFor === 'BUYER_AGENT') ? 'BUYER' : (rootDocData?.openFor === 'SELLER_AGENT') ? 'SELLER' : rootDocData?.openFor;
        // tempArr = mapClientsForSendEmail({ clients: (rootDocData?.openFor === "SELLER_AGENT") ? selectedData?.sellerIds : selectedData?.buyerIds, role });
        tempArr = mapClientsForSendEmail({ clients: (rootDocData?.openFor === "SELLER_AGENT") ? combinedClientsData.combinedSellerIds : combinedClientsData.combinedBuyerIds, role });
        break;
      default:
        tempArr = selectedData?.acceptedOfferSellers?.map((seller) => ({
          openFor: "SELLER",
          seller: seller?.fullName || null,
          key: seller?._id || null,
          name: seller?.fullName || null,
          emailPresent: !!seller?.email,
          email: seller?.email || null,
        }));
        break;
    }
    setClientsArray(tempArr);
    const initialChecked = {};
    tempArr.forEach((seller) => {
      initialChecked[seller._id] = { checked: !!seller.personId?.email, row: seller };
      if (seller.isCorp) {
        seller?.signers?.forEach((signer) => {
          initialChecked[signer._id] = { checked: !!signer.personId?.email, row: signer };
        });
      }
    });
    setSignerChecked(initialChecked);
    return () => {
      initialMountRef.current = false;
    };
  }, [selectedData, source, isEmailModalVisible, propertyDocs, personDocs]);

  useEffect(() => {
    if (!initialMountRef.current && Object.keys(signerChecked)?.length > 0) {
      handleSelectAllChange(true);
      initialMountRef.current = true;
    }
  }, [signerChecked, isEmailModalVisible]);

  const isButtonDisabled = () => {
    const noSignersChecked = Object.values(signerChecked).every((el) => !el.checked);
    const noEmailMessage = !emailList?.emailMessage || emailList?.emailMessage === "<p><br></p>";
    const noDocumentsSelected = !(data?.documentSelected?.length || data?.propertyDocsSelected?.length || data?.sharedSelected?.length || data?.clientDocumentSelected?.length || data?.documentWithoutBuyerSelected?.length);

    if (source === "SEND_EMAIL_BUYER_AGENT") {
      return noEmailMessage || noSignersChecked;
    }
    if (isEmailModalVisible?.sendMultipleDoc) {
      const noAgents = !agents;
      if (source === "SELLING_RTD") {
        return (noAgents && noSignersChecked) || (noEmailMessage && noDocumentsSelected);
      }
      return noSignersChecked || (noEmailMessage && noDocumentsSelected);
    }
    return noSignersChecked;
  };
  


  const { Panel } = Collapse;
  const handleInput = (event) => {
    setEmailList({
      ...emailList,
      emailMessage: event.target.innerHTML,
    });
  };
  const handleselectemail = (value) => {
    <div dangerouslySetInnerHTML={{ __html: selectedtext }} />;
    setSelectedText(value);
    setEmailList({
      ...emailList,
      emailMessage: value,
    });
    setEmail_templatevisible(false);
  };
  const handleEmail_templateCancel = () => {
    setEmail_templatevisible(false);
  };

  const handleEmailCancel = () => {
    if (nestedModalOpen && nestedModalOpen?.isAgent) {
      setIsEmailModalVisible({ ...setIsEmailModalVisible, isEmailModalVisible: false });
    } else if (source === "CONTACT_SELLING_RTD" || source === "CONTACT_BUYING_RTD" || source === "SELLING_RTD") {
      setIsEmailModalVisible({ ...isEmailModalVisible, isEmailModalOpen: false, sendMutipleDoc: false });
    } else {
      setIsEmailModalVisible(false);
    }

    setEmailList({
      ...emailList,
      emailMessage: "",
    });
    setSelectedText("");
    setSignerChecked({});
  };

  function processNestedDataforReadOnly(data) {
    let results = [];

    const isMongoDBObjectID = (id) => /^[a-f\d]{24}$/i.test(id);

    if (data && data.length) {
      data.forEach((item) => {
        if (!item?.isCorp && item?.isReadOnly) {
          if (item.personId?._id && isMongoDBObjectID(item.personId._id)) {
            // Only send personId if it's a valid MongoDB ObjectID
            results.push({ personId: item.personId._id });
          } else {
            results.push({
              ...(item.personId.fullName && { fullName: item.personId?.fullName }),
              ...(item.personId.email && { email: item.personId?.email }),
            });
          }
        } else if (item?.isCorp && item?.signers?.length > 0) {
          // Handle the case for corps
          item.signers.forEach((signer) => {
            if (signer.isReadOnly) {
              if (signer.personId?._id && isMongoDBObjectID(signer.personId?._id)) {
                results.push({ personId: signer.personId._id });
              } else {
                results.push({
                  ...(signer.personId?.email && { email: signer.personId.email }),
                });
              }
            }
          });
        }
      });
    }

    return results;
  }

  //validate read only emails
  // const validateEmail = (email) => {
  //   email = email.trim(); // Remove leading and trailing whitespace
  //   const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   return re.test(email);
  // };

  const handleReadOnlyEmailChange = (e) => {
    const value = e.target.value;
    setNewState({ ...newState, email: value });
};

  const handleSend = () => {
    const arr = Object.values(signerChecked)
      .filter((el) => el.checked && !el?.isReadOnly && !el?.isAddedByUser)
      .map((el) => ({
        ...(el?.row?.personId?._id ? { clientId: el?.row?.personId?._id } : {}),
        ...(el?.row?.openFor ? { openFor: el?.row?.openFor } : {}),
        ...(el?.row?.isCorp ? { key: el?.row?.corpKey } : el?.row?.personId?._id ? { key: el?.row?.personId?._id } : {}),
      }))
      .filter((el) => Object.keys(el).length !== 0);

    const readonlyClients = processNestedDataforReadOnly(clientsArray);
    const subjectVal = subjectForm?.getFieldValue("subject");

    let payloadData = {};

    if (nestedModalOpen && nestedModalOpen?.isAgent) {
      payloadData = {
        documentId: documentId,
        data: { ...(RTDInitiater === "BUYER" ? { showSeller: true } : { showBuyer: true }), contractType },
        contractType,
        client: client,
        offerId: selectedData?.offerId,
        userAuthKey,
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
        ...(subjectVal?.length ? {subject: subjectVal} : {}),
      };
      dispatch(transactionLaneDataHandle.updateDocument(payloadData));
    } else if (source === "SHARE_INDIVIDUAL_OFFER") {
      payloadData = {
        clientArray: arr,
        propertyId: offerTablePropertyId,
        offerId: selectedData?.offerId,
        sellerOpenOfferTable: true,
        sharedIndividualOffer: true,
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
        ...(subjectVal?.length ? {subject: subjectVal} : {}),
      };
      dispatch(offerTableHandle.shareOfferTableToClients(offerTableAuthKey, payloadData));
    } else if (source === "SHARE_OMD") {
      payloadData = {
        clientArray: arr,
        propertyId: offerTablePropertyId,
        sellerOpenOfferTable: true,
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
        ...(subjectVal?.length ? {subject: subjectVal} : {}),
      };
      dispatch(offerTableHandle.shareOfferTableToClients(offerTableAuthKey, payloadData));
    } else if (source === "TRANSACTION_CALENDAR") {
      payloadData = {
        clientArray: arr,
        ...(readonlyClients.length ? { readOnlyEmailsArray: readonlyClients } : {}),
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
        offerId: rootDocData?.offerId,
        openFor: rootDocData?.openFor,
        ...(subjectVal?.length ? {subject: subjectVal} : {}),
      };
      dispatch(calendarHandle.shareCalendarToClients({payloadData, userAuthKey}));
    } else if (source === "SEND_EMAIL_BUYER_AGENT") {
      const isObjectId = (id) => /^[a-f0-9]{24}$/.test(id);

      const clientArray = arr.filter((item) => item.clientId && item.key && isObjectId(item.clientId) && isObjectId(item.key));
      const readOnlyEmailsArray = Object.values(signerChecked).reduce((acc, item) => {
        const id = item?.row?.personId?._id;
        if (item.checked && !isObjectId(id)) {
          const fullName = item?.row?.personId?.fullName;
          const email = item?.row?.personId?.email;
          if (fullName || email) {
            acc.push({ fullName, email });
          }
        }
        return acc;
      }, []);
      payloadData = {
        ...(clientArray.length > 0 ? { clientArray } : {}),
        ...(readOnlyEmailsArray.length > 0 ? { readOnlyEmailsArray } : {}),
        propertyId: offerTablePropertyId,
        sentBy: "SELLER_AGENT",
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
        ...(subjectVal?.length ? {subject: subjectVal} : {}),
      };
      dispatch(offerTableHandle.shareMessageToBuyerAgents({ offerTableAuthKey, payloadData }));
    } else if (source === "CONTACT_SELLING_RTD" || source === "CONTACT_BUYING_RTD" || source === "SELLING_RTD") {
      let params = {};
      if (source === "CONTACT_SELLING_RTD" || source === "CONTACT_BUYING_RTD") {
        params = {
          clientArray: arr,
          ...(readonlyClients.length ? { readOnlyEmailsArray: readonlyClients } : {}),
          ...(readonlyClients?.length ? { readOnlyEmailsArray: readonlyClients } : {}),
          ...(data?.documentSelected?.length ? { documentIds: [...data?.documentSelected] } : {}),
          ...(data?.sharedSelected?.length ? { sharedFolderDocsArray: [...data?.sharedSelected] } : {}),
          ...(data?.propertyDocsSelected.length || data?.documentWithoutBuyerSelected.length
            ? {
                propertyDocsArray: [...(data?.propertyDocsSelected || []), ...(data?.documentWithoutBuyerSelected || [])],
              }
            : {}),
          ...(data?.clientDocumentSelected?.length ? { contactDocArray: [...data?.clientDocumentSelected] } : {}),
          ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
          sentBy: source === "CONTACT_SELLING_RTD" ? "SELLER_AGENT" : "BUYER_AGENT",
          ...(emailList?.emailMessage
            ? {
                emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1),
              }
            : {}),
          ...(rootDocData?.thirdpartyId ? { thirdpartyIdDoc: rootDocData?.thirdpartyId } : {}),
          ...(rootDocData?.offerId ? { offerId: rootDocData?.offerId } : {}),
          ...(rootDocData?.contractType === "ERTS" || rootDocData?.ertsId ? { ertsId: rootDocData?.contractType === "ERTS" ? rootDocData?.documentId : rootDocData?.ertsId } : {}),
          ...(rootDocData?.propertyId ? { propertyId: rootDocData?.propertyId } : {}),
          // ...(delegate ? { delegateSide: RTDInitiator === "BUYER" ? "BUYER" : "SELLER" } : {}),
          ...(subjectVal?.length ? {subject: subjectVal} : {}),
        };
      } else {
        params = {
          clientArray: arr,
          ...(readonlyClients.length ? { readOnlyEmailsArray: readonlyClients } : {}),
          ...(readonlyClients?.length ? { readOnlyEmailsArray: readonlyClients } : {}),
          ...(data?.documentSelected?.length ? { documentIds: [...data?.documentSelected] } : {}),
          ...(data?.sharedSelected?.length ? { sharedFolderDocsArray: [...data?.sharedSelected] } : {}),
          ...(data?.propertyDocsSelected.length || data?.documentWithoutBuyerSelected.length
            ? {
                propertyDocsArray: [...(data?.propertyDocsSelected || []), ...(data?.documentWithoutBuyerSelected || [])],
              }
            : {}),
          ...(data?.clientDocumentSelected?.length ? { contactDocArray: [...data?.clientDocumentSelected] } : {}),
          ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
          sentBy: source === "SELLING_RTD" ? "SELLER_AGENT" : "BUYER_AGENT",
           ...(emailList?.emailMessage
            ? {
                emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1),
              }
            : {}),
          ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {}),
          ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
          ...(selectedData?.contractType === "ERTS" || selectedData?.ertsId ? { ertsId: selectedData?.contractType === "ERTS" ? selectedData?.documentId : selectedData?.ertsId } : {}),
          ...(selectedData?.propertyId ? { propertyId: selectedData?.propertyId } : {}),
          // ...(delegate ? { delegateSide: RTDInitiator === "BUYER" ? "BUYER" : "SELLER" } : {}),
          ...(subjectVal?.length ? {subject: subjectVal} : {}),
        };
      }
    
      if(agents&&allBuyerAgentData&&source === "SELLING_RTD"){
        const modal = confirm({
          title: (
       <div className={styles.confirmTitle} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         <span>
           Are you sure you want to send the selected document{params?.propertyDocsArray?.length > 1 ? "s" : ""} to the following agent{ allBuyerAgentData.length > 1 ? "s" : ""}?
         </span>
         <span
           style={{
             position: 'absolute',
             top: '2px',
             right: '10px',
             cursor: 'pointer',
             fontSize: '25px',
              color:'grey',
             borderRadius: '50%',
             padding: '5px',
           }}
           onClick={() => modal.destroy()}
         >
           x
         </span>
       </div>
     ),
 
           okText: "Yes",
           cancelText: "No",
           width: 700,
           icon: <ExclamationCircleOutlined />,
           content:<div style={{ marginTop: "5px",maxWidth: "700px", overflowX: "auto",maxHeight:"450px",overflowY:"auto", whiteSpace: "nowrap" }}>{<RecipientList data={allBuyerAgentData} type="buyer" /> }</div>,
           onOk() {
             const updatedParams = {
               ...params,
               ...(agents?{sendToAgent:"BUYER_AGENT"}:{}),
             };
             dispatch(contractStoreHandle.SendMultipleDoc(updatedParams, userAuthKey,true));
           },
           onCancel() {
            if(arr.length<=0 &&readonlyClients.length<=0){
               message.error("Please add at least one recipient.")
             }else{
              dispatch(contractStoreHandle.SendMultipleDoc(params, userAuthKey,false));
           
         }}
        });

      }
      else{
        dispatch(contractStoreHandle.SendMultipleDoc(params, userAuthKey));
      }
    } 
     else {
      payloadData = {
        clientArray: arr,
        documentId,
        ...(selectedData?.ertsId ? { ertsId: selectedData?.ertsId } : {}),
        ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {}),
        ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id } : {}),
      };
      dispatch(transactionLaneDataHandle.sendEmailToClients(payloadData));
    }
    handleEmailCancel();
  };
  // console.log("clickedDataEmail", clickedDataEmail);
  const handleAddEmail = (values, check) => {
    let documentId = rootDocData?.documentId;
    let data; 
    if(!enterEmailModal?.inValid){
      data = {
        addToProperty: false,
      offerId: ["CONTACT_SELLING_RTD", "CONTACT_BUYING_RTD", "TRANSACTION_CALENDAR"].includes(source) ? offerId : selectedData?.offerId,
      ...((source === "CONTACT_BUYING_RTD" || (source === "TRANSACTION_CALENDAR" && rootDocData?.openFor === "BUYER_AGENT"))
      ? {
          buyerDetail: {
            fullName: clickedDataEmail?.personId?.fullName ? clickedDataEmail?.personId?.fullName : clickedDataEmail?.fullName,
            key: clickedDataEmail?.key ? clickedDataEmail?.key : clickedDataEmail?._id,
            isCorp: clickedDataEmail?.isCorp,
            corpKey: clickedDataEmail.corpKey,
            ...(values?.email?.length > 0 ? { email: values?.email?.toLowerCase() } : {}),
          },
        }
      : {
          sellerDetail: {
            fullName: clickedDataEmail?.personId?.fullName ? clickedDataEmail?.personId?.fullName : clickedDataEmail?.fullName,
            key: clickedDataEmail?.key ? clickedDataEmail?.key : clickedDataEmail?._id,
            isCorp: clickedDataEmail?.isCorp,
            corpKey: clickedDataEmail.corpKey,
            ...(values?.email?.length > 0 ? { email: values?.email?.toLowerCase() } : {}),
          },
        }),
      ...(values?._id ? { selectedId: values?._id } : {}),
      ...(check ? { newClient: true } : {}),
    };
    dispatch(transactionLaneDataHandle.addEmailFun({ data, userAuthKey, source, setEnterEmailModal, enterEmailModal, documentId, setIsEmailModalVisible, isEmailModalVisible, setCallPropertyDocsApi, form }));
    }else{
      data = {
        ...(values?.email?.length > 0 ? { email: values?.email?.toLowerCase() } : {}),
      }
      dispatch(transactionLaneDataHandle.updateEmail(data, clickedDataEmail?.personId?._id, userAuthKey, offerTablePropertyId,source));
    }
    setEnterEmailModal({ ...enterEmailModal, visible: false });
    // setIsEmailModalVisible({ ...isEmailModalVisible, isEmailModalOpen: false, sendMultipleDoc: false });
    // form.resetFields();
    // setIsEmailModalVisible(false);
  };
  const handleSelectAllChange = (checked) => {
    const updatedSignerChecked = {};
    clientsArray?.forEach((seller) => {
      if (!seller.isCorp && seller.personId?.email && validateEmail(seller?.personId?.email)) {
        updatedSignerChecked[seller._id] = { checked, row: seller };
      } else if (seller.isCorp) {
        seller?.signers?.forEach((signer) => {
          if (signer?.personId?.email && validateEmail(signer?.personId?.email)) {
            updatedSignerChecked[signer._id] = { checked, row: signer };
          }
        });
      }
    });
    setSignerChecked(updatedSignerChecked);
  };

  const handleClientsCheckboxChange = (sellerId, checked) => {
    setSignerChecked((prev) => ({
      ...prev,
      [sellerId]: { checked, row: prev[sellerId]?.row },
    }));
  };

  const CorporationCheckbox = ({ corporation = {} }) => {
    const [isCorpChecked, setIsCorpChecked] = useState(() => {
      const isInitiallyChecked = corporation?.signers?.every((signer) => signer?.personId?.email && validateEmail(signer?.personId?.email) && signerChecked[signer._id]?.checked);
      return isInitiallyChecked || false;
    });
    const handleCheckboxChange = (signerId, checked) => {
      setSignerChecked((prev) => ({
        ...prev,
        [signerId]: { checked, row: prev[signerId]?.row },
      }));
      if (corporation?.signers) {
        // Checking if all child checkboxes are checked
        const allChildChecked = corporation.signers.every((signer) => signer?.personId?.email && validateEmail(signer?.personId?.email) && signerChecked[signer._id]?.checked);
        // Updating the state of the parent checkbox
        setIsCorpChecked(allChildChecked);
      }
    };

    const handleParentCheckboxChange = (checked) => {
      setIsCorpChecked(checked);
      if (corporation?.signers) {
        corporation.signers.forEach((signer) => {
          if (signer?.personId?.email && validateEmail(signer?.personId?.email)) {
            setSignerChecked((prev) => ({
              ...prev,
              [signer._id]: { checked, row: prev[signer._id]?.row },
            }));
          }
        });
      }
    };
    const handleUpdateReadOnlyCorp = (signerId, checked) => {
      const updatedReadOnly = checked;

      setClientsArray((prevData) =>
        prevData.map((client) => {
          if (client.signers && client.signers.length > 0) {
            const updatedSigners = client.signers.map((signer) => (signer._id === signerId ? { ...signer, isReadOnly: updatedReadOnly } : signer));
            return { ...client, isReadOnly: updatedReadOnly, signers: updatedSigners };
          }
          return client;
        })
      );

      setSignerChecked((prev) => ({
        ...prev,
        [signerId]: { checked: true, row: prev[signerId]?.row, isReadOnly: checked },
      }));
    };

    return (
      <div>
        <Checkbox checked={isCorpChecked} onChange={(e) => handleParentCheckboxChange(e.target.checked)}>
         <text className={styles.coorporationfullName}> {corporation?.fullName || ""} {"Signers"}</text>
        </Checkbox>

        {corporation?.signers?.map((signer) => (
          <div style={{ marginLeft: "20px", display: "flex", alignItems: "center", whiteSpace: "nowrap", fontSize: "18px" }} key={signer?._id}>
            <Checkbox checked={signerChecked[signer._id]?.checked} onChange={() => handleCheckboxChange(signer._id, !signerChecked[signer._id]?.checked)} disabled={(!signer?.personId?.email || !validateEmail(signer?.personId?.email))} style={{ marginTop: "0px" }} />
            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
              <span className={styles.fullName}>{signer?.fullName}</span>
              <span className={styles.dash} >-</span>
              <span className={styles.email}>
                {signer?.personId?.email && validateEmail(signer?.personId?.email) ? (
                  <span className={styles.email}>
                    {signer.personId.email}
                    {(["CONTACT_SELLING_RTD" , "CONTACT_BUYING_RTD", "SELLING_RTD", "TRANSACTION_CALENDAR"].includes(source)) && (
                      <span style={breakPoint ? { fontSize: "10px", marginLeft: "5px" } : { marginLeft: "10px", fontWeight: "bold" }}>
                        <Checkbox checked={signerChecked[signer._id]?.isReadOnly} onChange={(event) => handleUpdateReadOnlyCorp(signer._id, event.target.checked)}>
                          <span  className={styles.readOnly}>Read Only</span>
                        </Checkbox>
                      </span>
                )}
                  </span>
                ) : (
                  (signer?.personId?.email && !(validateEmail(signer?.personId?.email))) ?
                                  <>
                                    <span style={{cursor:"pointer"}} 
                                      onClick={() => {
                                        setClickedDataEmail(signer);
                                        setEnterEmailModal({ ...enterEmailModal, visible: true, inValid: true });
                                        form?.setFieldsValue({ email: signer?.personId?.email })
                                      }}>
                                      <span style={{ fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "18px", color: "blue", textDecoration: "underline" }}>{signer?.personId?.email}</span>
                                      <Tooltip title="This email is not valid. Please enter a valid email.">
                                        <span><WarningOutlined style={{ color:"orange", fontSize:"18px", marginLeft:"8px" }}/></span>
                                      </Tooltip>
                                    </span>
                                  </> :
                  <button
                    onClick={() => {
                      setClickedDataEmail(signer);
                      setEnterEmailModal({ ...enterEmailModal, visible: true });
                    }}
                    style={{ fontSize: isSmallScreen ? "10px" : isMediumScreen ? "12px" : "14px", backgroundColor: "#1890ff", color: "white", border: "none", borderRadius: "2px", cursor: "pointer" }}
                  >
                    Add Email
                  </button>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const handleDeleteReadOnly = (id) => {
    let clients = clientsArray || [];

    let tobeDelete = clients.find((client) => client?.personId?._id === id && client.isReadOnly);
    if (tobeDelete) {
      let updatedClients = clients.filter((client) => !(client?.personId?._id === id && client.isReadOnly));

      setClientsArray(updatedClients);

      let updatedSignerChecked = { ...signerChecked };
      delete updatedSignerChecked[id];
      setSignerChecked(updatedSignerChecked);

      message.success("Read only recipient deleted successfully!");
    } else {
      message.error("Read only recipient not found.");
    }
  };
  const handleUpdateReadOnly = (selectedClient, checked) => {
    const updatedReadOnly = checked;

    setClientsArray((prevData) => prevData.map((client) => (client?._id === selectedClient?._id ? { ...client, isReadOnly: updatedReadOnly } : client)));

    setSignerChecked((prevState) => ({
      ...prevState,
      [selectedClient?._id]: {
        ...prevState[selectedClient?._id],
        isReadOnly: updatedReadOnly,
        isAddedByUser: false,
      },
    }));
  };
  const handleReadOnlyNameChange = (e) => {
    const value = e.target.value;
    // Filter out empty strings
    setNewState({ ...newState, fullName: value });
    // Update the state with the array of emails
  };
  const handleSelectChange = (id) => {
    let [filteredValue] = emailSignData
      ?.filter((el) => el?._id === id)
      .map((el) => {
        return { ...el, isDefault: true };
      });
    setEmailSignatureId(filteredValue);
  };
  const handleEmailAddress = () => {
 
    // Get single email and name values
    let email = newState?.email?.trim(); // Get email value
    let fullName = newState?.fullName?.trim(); // Get name value
    // Check if email and name are provided
    if (email && fullName) {
      // Generate a unique ID for each email
      let uniqueEmailId = uuidv4().replace(/-/g, "").slice(0, 26);

      let data = {
        personId: {
          email: email,
          fullName: fullName, // Include the name here
          _id: uniqueEmailId,
        },
        isReadOnly: true,
        isAddedByUser: true,
        _id: uniqueEmailId,
      };

      let signerData = {
        checked: true,
        isReadOnly: true,
        isAddedByUser: true,
        row: {
          personId: {
            email: email,
            fullName: fullName, // Include the name here
          },
          _id: uniqueEmailId,
        },
      };

      // Update state
      setClientsArray((prevArray) => [...prevArray, data]);
      setSignerChecked((prevChecked) => ({ ...prevChecked, [uniqueEmailId]: signerData }));

      // Reset email and name values
      setNewState({ email: "", fullName: "" }); // Reset email and name fields
      form.resetFields();
    }
    setShowTextArea(false)
  };
  const closeClientListModal = () => {
    setShowClientListModal(false);
    setShowTextArea(false);
    setNewState({ email: "", fullName: "" });
  };
  const checkClient = (data, selectedClient) => {
    for (const client of data) {
      if (client.isCorp) {
        // If isCorp is true, loop over the signers array
        for (const signer of client.signers) {
          if (signer.personId._id === selectedClient._id) {
            return true;
          }
        }
      } else {
        // If isCorp is false, compare personId._id directly
        if (client.personId._id === selectedClient._id) {
          return true;
        }
      }
    }
    return false;
  };
  const addClient = (selectedClient) => {
    // if (!selectedClient?.email) {
    //   message.error("Please add the client's email to proceed!");
    //   return;
    // }
    const clients = clientsArray || [];

    if (clients.length >= 10) {
      message.error(`Maximum number of clients already added.`);
      return;
    }

    let check = checkClient(clientsArray, selectedClient);

    if (check) {
      message.error(`User already exists in read only recipients list.`);
      return;
    } else {
      setClientsArray([
        ...clientsArray,
        {
          fullName: generateFullNameFromObj(selectedClient),
          personId: { email: selectedClient?.email, _id: selectedClient._id },
          isCorp: false,
          isReadOnly: true,
          isAddedByUser: true,
          _id: selectedClient._id,
          signers: [],
        },
      ]);

      setSignerChecked({
        ...signerChecked,
        [selectedClient._id]: {
          fullName: generateFullNameFromObj(selectedClient),
          personId: { email: selectedClient?.email },
          isCorp: false,
          isReadOnly: true,
          _id: selectedClient._id,
          signers: [],
          checked: true,
        },
      });
      message.success("Read only recipient added!");
    }
  };
  const NormalPersonDetails = ({ person }) => {
    return (
      <>
        <Checkbox checked={signerChecked[person._id]?.checked} onChange={() => handleClientsCheckboxChange(person._id, !signerChecked[person._id]?.checked)} style={{ fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px", marginRight: "10px" }} disabled={!person.personId?.email || !validateEmail(person?.personId?.email)} />
        <div>
          <span className={styles.fullName}>{person.fullName}</span>
          {person.fullName && <span className={styles.dash}>-</span>}

          {person?.personId?.email && validateEmail(person?.personId?.email) ? (
            <>
              {person.personId?.fullName && person?.isAddedByUser && (
                <>
                  {" "}
                  <span className={styles.fullName}> {person?.personId?.fullName} </span>
                  <span className={styles.dash}>-</span>
                </>
              )}
              <span className={styles.email}>
                {person?.personId?.email}
                <span style={isSmallScreen ? { fontSize: "10px", marginLeft: "5px" } : { marginLeft: "10px", fontWeight: "bold" }}>
                  {person?.isAddedByUser ? null : (
                    <Checkbox checked={person?.isReadOnly} style={{ fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px", marginRight: "10px" }} onChange={(event) => handleUpdateReadOnly(person, event.target.checked)}>
                      <span className={styles.readOnly}>Read Only</span>
                    </Checkbox>
                  )}
                </span>
              </span>
              <span style={{ color: "grey", fontWeight: "bold" }}>
                <span  className={styles.userReadOnly}>{person?.isAddedByUser ? "(Read Only)" : ""}</span>
                <span style={{ cursor: "pointer" }}>
                  {"  "}
                  {person?.isAddedByUser && (
                    <Tooltip title="Delete readonly recipient!" trigger={["hover", "click"]}>
                      <DeleteOutlined onClick={() => handleDeleteReadOnly(person?.personId?._id)} />
                    </Tooltip>
                  )}
                </span>
              </span>
            </>
          ) : (
            (person?.personId?.email && !(validateEmail(person?.personId?.email))) ?
                                  <>
                                    <span style={{cursor:"pointer"}} 
                                      onClick={() => {
                                        setClickedDataEmail(person);
                                        setEnterEmailModal({ ...enterEmailModal, visible: true, inValid: true });
                                        form?.setFieldsValue({ email: person?.personId?.email })
                                      }}>
                                      <span style={{ fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "18px", color: "blue", textDecoration: "underline" }}>{person?.personId?.email}</span>
                                      <Tooltip title="This email is not valid. Please enter a valid email.">
                                        <span><WarningOutlined style={{ color:"orange", fontSize:"18px", marginLeft:"8px" }}/></span>
                                      </Tooltip>
                                    </span>
                                  </> :
            <button
              onClick={() => {
                setClickedDataEmail(person);
                setEnterEmailModal({ ...enterEmailModal, visible: true });
              }}
              style={{ fontSize: isSmallScreen ? "10px" : isMediumScreen ? "12px" : "14px", backgroundColor: "#1890ff", color: "white", border: "none", borderRadius: "2px", cursor: "pointer" }}
            >
              Add Email
            </button>
          )}
        </div>
      </>
    );
  };
  const clients = clientsArray?.filter((client) => !client.isAgent);


  return (
    <>
      {/* client list modal  */}
      {showClientListModal && (
        <Modal visible={showClientListModal} onCancel={() => setShowClientListModal(false)} footer={false} style={{ marginTop: "-80px" }}>
          <ClientList source={(source === "TRANSACTION_CALENDAR") ? source :"offerTable"} setshowModal={setShowClientListModal} accessTokan={["CONTACT_SELLING_RTD", "CONTACT_BUYING_RTD", "TRANSACTION_CALENDAR"].includes(source) ? userAuthKey : offerTableAuthKey} onAdd={addClient} handleCancel={closeClientListModal} openFor={rootDocData?.openFor} />
        </Modal>
      )}
      {ispreview && (
        <Modal width={"800px"} visible={ispreview} footer={false} onCancel={() => setIspreview(false)}>
          {emailSignatureId && (
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                width: "100%",
                borderRadius: "5px",
                padding: "10px",
                margin: "auto",
                marginTop: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span
                    style={{
                      color: "gray",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Name: {emailSignatureId?.title}
                  </span>
                </div>
              </div>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "left",
                  lineHeight: "0.8",
                  marginTop: "5px",
                }}
              >
                {emailSignatureId?.footerText && <div dangerouslySetInnerHTML={{ __html: emailSignatureId.footerText }} />}
              </p>

              <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>{emailSignatureId?.signatureImageUrl && <Image src={emailSignatureId?.signatureImageUrl} alt="Signature" loading="lazy" />}</div>
            </div>
          )}
        </Modal>
      )}
      {Email_templatevisible && <EmailTemplateModal Email_templatevisible={Email_templatevisible} setEmail_templatevisible={setEmail_templatevisible} handleEmail_templateCancel={handleEmail_templateCancel} handleselectemail={handleselectemail} />}

      {enterEmailModal && <AddEmail enterEmailModal={enterEmailModal} setEnterEmailModal={setEnterEmailModal} form={form} handleAddEmail={handleAddEmail} clickedDataEmail={clickedDataEmail} role={role} selectedData={selectedData} userAuthKey={userAuthKey} source={source} offerId={offerId} rootDocData={rootDocData} setCallPropertyDocsApi={setCallPropertyDocsApi} />}

      <div style={{ maxHeight: "550px", overflow: "hidden", overflowY: "auto", padding: "5px" }}>
        {addEmailLoading ? (
          <div className={styles.spin}>
            <Spin size="medium" />
          </div>
        ) : (
          <div>
            {propertDocsLoading || personDocsLoading || (source === "TRANSACTION_CALENDAR" && getTransactionLaneLoading) ? (
              <div className={styles.spin}>
                <Spin size="medium" />
              </div>
            ) : (
              <div style={{ overflowX: "auto", paddingBottom: isSmallScreen ? "10px" : "20px" }}>
                {["CONTACT_SELLING_RTD", "CONTACT_BUYING_RTD", "SELLING_RTD", "TRANSACTION_CALENDAR"].includes(source) ? (
                  <>
                    {clients?.length > 0 && <text className={styles.recipientName}>{`Recipient${clients?.length > 1 ? "s" : ""}`}:</text>}
                    {clients?.map((seller) => (
                      <div key={seller?._id} style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                        {!seller?.isCorp && <NormalPersonDetails person={seller} />}
                        {seller.isCorp && <CorporationCheckbox corporation={seller} />}
                      </div>
                    ))}
                    {showTextArea && (
                      <div className={styles.addEmailForm}>
                        <Form form={form} onFinish={handleEmailAddress}>
                          <div style={{ display: "flex", width: "100%" }}>
                            <Form.Item style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setShowClientListModal(true)}>
                              <Tooltip title="Add Existing Contact" trigger={["click", "hover"]}>
                                <TiContacts style={{ fontSize: "35px" }} />
                              </Tooltip>
                            </Form.Item>

                            <Form.Item name="fullName" style={{ width: "30%", marginRight: "8px" }}>
                              <div className="customForm">
                                <Input rows={1} placeholder="Enter Name" onChange={handleReadOnlyNameChange} value={newState.fullName || ""} style={{ resize: "none", width: "100%" }} />
                              </div>
                            </Form.Item>

                            <Form.Item
                              name="email"
                              rules={[{ required: true, type: "email", message: "Please enter a valid email address" }]}
                              style={{ width: "90%", marginRight: "8px" }} // Larger proportion for the email box
                            >
                              <div className="customForm">
                                <Input placeholder="Enter email address" onChange={handleReadOnlyEmailChange} value={newState.email || ""} type="email" style={{ resize: "none", width: "100%" }} />
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="email"
                              style={{ width: "15%" }} // Larger proportion for the email box
                            >
                              <div>
                                <Button type="primary" htmlType="submit" disabled={!newState?.email || !newState?.fullName}>
                                  Add
                                </Button>
                              </div>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    )}
                    {/* {agents?.length > 0 && <h2 style={{ color: "gray", marginBottom: "-5px" }}> Agents:</h2>}
                    {agents?.map((seller) => (
                      <div key={seller?._id} style={breakPoint ? { display: "flex", alignItems: "center" } : { display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                        <NormalPersonDetails person={seller} />
                      </div>
                    ))} */}
                  </>
                    ) : (
                      <>
                    {clientsArray?.length > 0 && <text style={{ fontSize: "large", color: "grey", fontWeight: "bold" }}>{`Recipient${clientsArray?.length > 1 ? "s" : ""}`}:</text>}
                        {clientsArray?.map((seller) => (
                          <>
                            <div key={seller?._id} style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                              {!seller?.isCorp && (
                                <>
                                  <Checkbox checked={signerChecked[seller._id]?.checked} onChange={() => handleClientsCheckboxChange(seller?._id, !signerChecked[seller?._id]?.checked)} style={{ fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px", marginRight: "10px" }} disabled={!seller?.personId?.email} />
                                  <div>
                                    <span className={styles.fullName}>{seller?.fullName || seller?.personId?.fullName}</span>
                                    <span className={styles.dash}>-</span>
                                    {seller?.personId?.email ? (
                                      <span className={styles.email}>{seller?.personId?.email}</span>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          setClickedDataEmail(seller);
                                          setEnterEmailModal({ ...enterEmailModal, visible: true });
                                        }}
                                        style={{ fontSize: isSmallScreen ? "10px" : isMediumScreen ? "12px" : "14px", backgroundColor: "#1890ff", color: "white", border: "none", borderRadius: "2px", cursor: "pointer" }}
                                      >
                                        Add Email
                                      </button>
                                    )}
                                  </div>
                                </>
                              )}
                              {seller?.isCorp && <CorporationCheckbox corporation={seller} />}
                            </div>
                          </>
                        ))}
                      </>
                    )}
                {source === "SELLING_RTD" &&
                  (loading ? (
                    <Loading />
                  ) : (
                    allBuyerAgentData?.length > 0 && (
                      <>
                        <div style={{ display: "flex", fontWeight: "bold", color: "grey", fontSize: "18px" }}>
                          <Checkbox checked={agents} onChange={(e) => handleSetAgents(e.target.checked)}>
                            <text className={styles.checkBox}> {`Buyer Agent${allBuyerAgentData?.length > 1 ? "s" : ""}`}</text>
                          </Checkbox>
                        </div>
                        <RecipientList data={allBuyerAgentData} type="buyer" />
                      </>
                    )
                  ))}
              </div>
            )}

            
            <SubjectLine form={subjectForm} data={data} source={source} clientDocs={clientDocs} selectedData={selectedData} rootDocData={rootDocData} propertyAddress={propertyAddress}/>

            {isEmailModalVisible?.sendMultipleDoc && (
              <div
                style={{
                  marginTop: "-10px",
                  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                }}
              >
                <Collapse ghost accordion defaultActiveKey={["1"]}>
                  <Panel header={<span style={{ fontSize: "15px" }}>Document List</span>} key="1">
                    <div>
                      <DocumentList role={role} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} RTDInitiator={RTDInitiater} isEmailModalVisible={isEmailModalVisible} contractAuthKey={userAuthKey} offerId={source === "SELLING_RTD" ? selectedData?.offerId : offerId} data={data} setData={setData} documentId={documentId} selectedData={selectedData} selectedStatus={role} rootDocData={rootDocData} screenSource={source} />
                    </div>
                  </Panel>
                </Collapse>
              </div>
            )}

            <ContentEditable setEmail_templatevisible={setEmail_templatevisible} selectedtext={selectedtext} handleInput={handleInput} emailList={emailList} setEmailList={setEmailList} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />

            <div style={{ marginTop: isSmallScreen ? "40px" : isMediumScreen ? "15px" : "10px", display: "flex" }}>
              <Checkbox checked={emailSignatureId.isDefault} onChange={(e) => setEmailSignatureId({ ...emailSignatureId, isDefault: e.target.checked })} disabled={!emailSignatureId.isDefault} style={{ fontSize: isSmallScreen ? "10px" : isMediumScreen ? "12px" : "14px" }}></Checkbox>
              <div className={styles.emailSignature}> Include Email Signature</div>
              <Select
                value={emailSignatureId._id} // Use value instead of defaultValue
                style={{ width: isSmallScreen ? 150 : 200, marginLeft: "5px",marginBottom:"20px" }}
                loading={emailSignLoading}
                onChange={(value) => handleSelectChange(value)}
                disabled={!emailSignatureId.isDefault}
              >
                {emailSignData &&
                  emailSignData?.length &&
                  emailSignData?.map((el, ind) => (
                    <Option key={ind} value={el?._id}>
                      {el?.title}
                    </Option>
                  ))}
              </Select>
              {emailSignatureId?.isDefault && (
                <Button style={{ fontSize: isSmallScreen ? "10px" : isMediumScreen ? "12px" : "14px" }} icon={<EyeOutlined />} onClick={() => setIspreview(true)} type="link">
                  Preview
                </Button>
              )}
            </div>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button type="primary" size={isSmallScreen ? "small" : "middle"} disabled={isButtonDisabled()} onClick={handleSend}>
                Send
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { SendEmailModal };
