/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Tabs } from "antd";
import styles from "../styles/CobuyerCosellerList.module.css";
import EditCorpOrClient from "./EditCorpOrClient";
const { TabPane } = Tabs;
function CobuyerCosellerList({ clientType, contractAuthKey, currentUrlId, rootDocData, sourceType }) {
  return (
    <>
      <div className={styles.contentDiv}>
        <Tabs defaultActiveKey={"CLIENTS"} size="small" type="card">
          {sourceType === "NORMAL" ? (
            <>
              <TabPane tab={<span style={{ fontSize: "18px" }}>Persons</span>} key="CLIENTS">
                <EditCorpOrClient sourceType={sourceType} source={"CLIENTS"} currentUrlId={currentUrlId} clientType={clientType} rootDocData={rootDocData} contractAuthKey={contractAuthKey} />
              </TabPane>
              <TabPane tab={<span style={{ fontSize: "18px" }}>Corporation/Trust/Other </span>} key="CORP">
                <EditCorpOrClient sourceType={sourceType} source={"CORP"} currentUrlId={currentUrlId} clientType={clientType} rootDocData={rootDocData} contractAuthKey={contractAuthKey} />
              </TabPane>
            </>
          ) : null}
          {sourceType === "MANUAL_ADD_CLIENTS" ? (
            <>
              <TabPane tab={<span style={{ fontSize: "18px" }}>Persons</span>} key="MANUAL_SELLERS">
                <EditCorpOrClient sourceType={sourceType} source={"CLIENTS"} currentUrlId={currentUrlId} clientType={clientType} rootDocData={rootDocData} contractAuthKey={contractAuthKey} />
              </TabPane>
              <TabPane tab={<span style={{ fontSize: "18px" }}>Corporation/Trust/Other </span>} key="CORP">
                <EditCorpOrClient sourceType={sourceType} source={"CORP"} currentUrlId={currentUrlId} clientType={clientType} rootDocData={rootDocData} contractAuthKey={contractAuthKey} />
              </TabPane>
            </>
          ) : null}
        </Tabs>
      </div>
    </>
  );
}

export default CobuyerCosellerList;
