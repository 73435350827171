/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { List, Avatar, Switch, Input, Typography, Spin, message, Tooltip } from "antd";
import { PlusCircleOutlined, UserOutlined, SearchOutlined, CheckCircleFilled } from "@ant-design/icons";
import styles from "../styles/ClientList.module.css";
import axios from "axios";
import AddClient from "./AddClient";
import { useMediaQuery } from "react-responsive";
const apiURL = process.env.REACT_APP_BASE_URL;

const { Title } = Typography;

function ClientList({ onAdd, accessTokan, handleCancel, source, addedClients, openFor, }) {
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [clientsState, setClientsState] = useState([]);
  const [clientType, setClientType] = useState("all");
  const [clientcount, setClientCount] = useState(18);
  const [loading, setLoading] = useState(false);
  const [getClientData, setClientData] = useState([]);
  const [count, setCount] = useState(1);
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery({maxWidth:500});
  const inputRef = useRef(null);

  const getRelatorCustomerList = (count, query = {}, debounce) => {
    if (count === 1 || debounce) {
      setLoading(true);
      axios
        .get(`${apiURL}/realtorCustomerList`, {
          params: {
            ...query,
            section:clientType.toUpperCase()
          },
          headers: {
            accept: "application/json",
            authorization: `bearer ${accessTokan}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setClientData(res.data?.info);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data.message || "We encountered an issue while fetching data.Please try again later!");
        });
      setCount(count + 1);
    }
  };

  useEffect(() => {
    getRelatorCustomerList(1, {}, false);
   }, [clientType]);

  useEffect(() => {
    if ((getClientData?.clients && getClientData?.clients[0] && getClientData?.clients[0]?.data)) {
      setClientsState(getClientData?.clients[0]?.data);
    }
  }, [getClientData?.clients, clientType]);

  const changeClientType = (checked) => {
    if (checked) {
      setClientType("active");
    }
    if (!checked) {
      setClientType("all");
    }
    setClientCount(16);
  };
  let handleLoadMore = () => {
    setClientCount(clientcount + 32);
  };

  const debounceFun = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const searchClientFunc = debounceFun((value) => {
    getRelatorCustomerList(count, { search: value }, "debounce");
  }, 1000);

  useEffect(() => {
    if (showSearchInput && !loading) {
      inputRef.current.focus();
    }
  }, [showSearchInput,loading]);

  return (
    <div style={{ padding: isMobile ? "5px": "15px" }}>
      <AddClient visible={visible} setVisible={setVisible} setClientData={setClientData} accessTokan={accessTokan} source={source} openFor={openFor}  />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
          marginBottom: "10px",
        }}
      >
        <div>
          <SearchOutlined style={{ fontSize: isMobile ? "20px" : "30px", color: "#085191" }} onClick={() => setShowSearchInput(!showSearchInput)} />
        </div>
        <div style={{ textAlign: "center" }}>
          <Title level={isMobile ? 4 : 3} style={{color:'rgba(0, 0, 0, 0.45)'}}> {clientType === "all" ? "ALL" : "ACTIVE"} CONTACTS</Title>
        </div>
        <div style={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          gap:isMobile ? "10px":"20px",
        }}>
        <Tooltip placement="top" title={"Create Contact"}>
          <PlusCircleOutlined
            onClick={() => {
              setVisible(true);
            }}
            style={{ fontSize: isMobile ? "20px" : "30px", color: "#085191" }}
            />
            </Tooltip>
          <Switch size={isMobile ? "small":"default"}
            onChange={changeClientType}
          />
        </div>
      </div>
      <div>
        {showSearchInput && (
          <Input
            ref={inputRef}
            onChange={(e) => searchClientFunc(e.target.value)}
            placeholder="Search a Client"
            autoFocus
            allowClear
            style={{
              height: "40px",
            }}
          />
        )}
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
            height: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div
          id="parent-scroll-div"
          style={{
            height: showSearchInput ? "calc(100vh - 360px)" : "calc(100vh - 315px)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          onScroll={(e) => {
            if (Math.round(e.target.scrollHeight - Math.round(e.target.scrollTop)) < 1000) {
              handleLoadMore();
            }
          }}
        >
          <List
            className={styles.demoloadlisting}
            bordered={true}
            itemLayout="horizontal"
            dataSource={(clientsState && clientsState.slice(0, clientcount)) || []}
            renderItem={(item) => {
              return (
                <List.Item
                  className={styles.clientListItem}
                  style={
                    {
                      //   background: item._id === selectedClient ? "#92b3d1" : "white",
                    }
                  }
                  key={item._id}
                  onClick={() => {
                    onAdd(item);
                    source !== "ContactSheet" && handleCancel();
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      item.profileImg && item.profileImg.original ? (
                        <Avatar size={45} className={styles.useravtar} src={item.profileImg.original} />
                      ) : item.firstName ? (
                        <Avatar size={45} style={{ background: "#085191" }}>
                          {(item.firstName ? item.firstName?.[0]?.toUpperCase() : "") + "" + (item.lastName ? item.lastName?.[0]?.toUpperCase() : "")}
                        </Avatar>
                      ) : (
                        <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                      )
                    }
                    title={<div>{(item.firstName ? item.firstName : "") + " " + (item.middleName ? item.middleName : "") + " " + (item.lastName ? item.lastName : "")}</div>}
                    description={item.email ? item.email : <div style={{ color: "red" }}>Please Enter the email ID</div>}
                  />
                  {addedClients?.includes(item.email) ? <div><CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: "green" }} /></div> : ""}
                </List.Item>
              );
            }}
          />
        </div>
      )}
    </div>
  );
}

export { ClientList };
