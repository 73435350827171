import { validateEmail } from "../../CreateOffer/features/helperFunctions";

export const checkAllRequiredFields = (CurrentPdfImportData) => {
  if (!Object.keys(CurrentPdfImportData)?.length) return false;
  const requiredFields = ["Buyers", "FullAddress", "BuyerBrokerName", "BuyerBrokerEmailAddress", "PurchasePrice", "EarnestMoney", "CashAtClosing", "PossessionDate", "ClosingDate", "AcceptanceDeadlineDate"];

  // checking if all required fields are present and have non-empty values
  for (const field of requiredFields) {
    if (!CurrentPdfImportData.hasOwnProperty(field) || (Array.isArray(CurrentPdfImportData[field]) && CurrentPdfImportData[field].length === 0) || !CurrentPdfImportData[field]) {
      return false;
    }
  }
  isAtleastOneClientPresent(CurrentPdfImportData.Buyers);

  if(!validateEmail(CurrentPdfImportData["BuyerBrokerEmailAddress"])){
    return false;
  }

  return true;
};

export const isAtleastOneClientPresent = (clients, rootDocData, checkSide) => {
  if (Array.isArray(clients) && clients.length > 0) {
    for (const client of clients) {
      if (client.isCorp && client.signers.length > 0) {
        return true;
      } else if (!client.isCorp && client.fullName) {
        return true;
      }
    }
  }
  
  if(checkSide === "BUYER"){
    if(!rootDocData?.buyerRequired){
      return true;
    }
  }
  else if(checkSide === "SELLER"){
    if(!rootDocData?.sellerRequired){
      return true;
    }
  }

  return false; 
};
