/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { buildPropertyFullAddress } from "../../Common/utils/universalFuntions";
import { dirSelectOptions } from "../../Common/commondata/commonData";

const EditPropertyAddressModal = ({ modalState, setModalState, currentUrlId, contractAuthKey, rootDocData }) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [anyInputChanged, setAnyInputChanged] = useState(false);
  const { editPropertyAddressModal } = modalState || {};

  useEffect(() => {
    if (editPropertyAddressModal) {
      const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
      form.setFieldsValue({
        StreetNumber: localData?.StreetNumber || "",
        StreetName: localData?.StreetName || "",
        DirPrefix: localData?.DirPrefix || "",
        DirSuffix: localData?.DirSuffix || "",
        Unit: localData?.Unit || "",
        City: localData?.City || "",
        State: localData?.State || "",
        ZipCode: localData?.ZipCode || "",
      });
    }
  }, [editPropertyAddressModal]);

  const handleEditAddressModalCancel = () => {
    setModalState((prevState) => ({ ...prevState, editPropertyAddressModal: false }));
    form.resetFields();
  };

  const handleUpdateAddress = () => {
    form
      .validateFields()
      .then((values) => {
        const trimmedValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, typeof value === "string" ? value.trim() : value]));
        trimmedValues.FullAddress = buildPropertyFullAddress(trimmedValues);
        const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
        const newData = { ...localData, ...trimmedValues };
        localStorage.setItem(currentUrlId, JSON.stringify(newData));
        dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, valueObj: trimmedValues }));
        setModalState((prevState) => ({ ...prevState, editPropertyAddressModal: false }));
        message.success("Property Address Updated Successfully!");
      })
      .catch((info) => {});
  };

  return (
    <>
      <Modal
        visible={modalState?.editPropertyAddressModal}
        width="800px"
        title={<span style={{ display: "flex", justifyContent: "center", fontSize: screens.xs ? "18px" : "22px", color: "grey" }}>EDIT PROPERTY ADDRESS</span>}
        onCancel={handleEditAddressModalCancel}
        footer={
          <>
            <Button onClick={handleEditAddressModalCancel}>Cancel</Button>
            <Button type="primary" disabled={!anyInputChanged} onClick={handleUpdateAddress}>
              Update
            </Button>
          </>
        }
      >
        <div style={{ maxHeight: "60vh", padding: "10px 20px 0px 10px", overflow: "auto" }}>
          <Form form={form} layout="vertical" style={{ padding: 10 }} scrollToFirstError onValuesChange={() => setAnyInputChanged(true)}>
            <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1" name="StreetNumber" label="Street Number" rules={[{ required: true, message: "Please enter the street number." }]}>
                  <Input type="number" placeholder="Enter Street Number" allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1"  name="StreetName" label="Street Name" rules={[{ required: true, message: "Please enter the street name." }]}>
                  <Input placeholder="Enter Street Name" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1" name="DirPrefix" label="Direction Prefix">
                  <Select placeholder="Select Direction" options={dirSelectOptions} allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1"  name="DirSuffix" label="Direction Suffix">
                  <Select placeholder="Select Direction" options={dirSelectOptions} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1" name="Unit" label="Unit">
                  <Input placeholder="Enter Unit" allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1"  name="City" label="City" rules={[{ required: true, message: "Please enter the city name." }]}>
                  <Input placeholder="Enter City Name" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item  className="lbl1"  name="State" label="State" rules={[{ required: true, message: "Please enter the state name." }]}>
                  <Input placeholder="Enter State Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 24 : 11}>
                <Form.Item
                  className="lbl1"
                  name="ZipCode"
                  label="Zip Code"
                  hasFeedback
                  rules={[
                    { required: true, message: "" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value?.toString().trim().length >= 5 && value.toString().trim().length <= 6) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please Enter a valid zip code."));
                      },
                    }),
                  ]}
                >
                  <Input type="number" maxLength={"6"} allowClear placeholder="Enter Zip Code" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditPropertyAddressModal;
