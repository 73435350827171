import { getParsePrice } from "../../../Common/commondata/commonData";

const accounting = require("accounting");

const calculatePriceTable = (table, source) => {
  let { PurchasePrice, EarnestMoney, NewLoan, AssumptionBalance, PrivateFinancing, SellerFinancing } = table;

  let CashAtClosing, TotalLeftColumn, TotalRightColumn;
  TotalLeftColumn = PurchasePrice;
  TotalRightColumn = PurchasePrice;

  if (source === "ChecboxUnchecked") {
   EarnestMoney = getParsePrice((PurchasePrice * 1) / 100);
   NewLoan = getParsePrice((PurchasePrice * 95) / 100);
   CashAtClosing = getParsePrice((PurchasePrice * 4) / 100);
    AssumptionBalance = 0.00;
    PrivateFinancing = 0.00;
    SellerFinancing = 0.00;
  } else {
    CashAtClosing = PurchasePrice - (EarnestMoney + NewLoan + AssumptionBalance + PrivateFinancing + SellerFinancing);
  }
  return {
    PurchasePrice: accounting.formatNumber(PurchasePrice, 2),
    EarnestMoney: accounting.formatNumber(EarnestMoney, 2),
    NewLoan: accounting.formatNumber(NewLoan, 2),
    AssumptionBalance: accounting.formatNumber(AssumptionBalance, 2),
    PrivateFinancing: accounting.formatNumber(PrivateFinancing, 2),
    SellerFinancing: accounting.formatNumber(SellerFinancing, 2),
    CashAtClosing: accounting.formatNumber(CashAtClosing, 2),
    TotalLeftColumn: accounting.formatNumber(TotalLeftColumn, 2),
    TotalRightColumn: accounting.formatNumber(TotalRightColumn, 2),
  };

  // if we want the values to be blank then do this.
  // return {
  //   PurchasePrice: PurchasePrice && accounting.formatNumber(PurchasePrice, 2) || null,
  //   EarnestMoney: EarnestMoney && accounting.formatNumber(EarnestMoney, 2) || null,
  //   NewLoan: NewLoan && accounting.formatNumber(NewLoan, 2) || null,
  //   AssumptionBalance: AssumptionBalance && accounting.formatNumber(AssumptionBalance, 2) || null,
  //   PrivateFinancing: PrivateFinancing && accounting.formatNumber(PrivateFinancing, 2) || null,
  //   SellerFinancing: SellerFinancing && accounting.formatNumber(SellerFinancing, 2) || null,

  //   CashAtClosing: accounting.formatNumber(CashAtClosing, 2),
  //   TotalLeftColumn: accounting.formatNumber(TotalLeftColumn, 2),
  //   TotalRightColumn: accounting.formatNumber(TotalRightColumn, 2),
  // };
};

export default calculatePriceTable;
