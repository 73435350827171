import { clienAuthApi } from "../utils/api";
import { clientAuthConstants } from "./action-types";
const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

const verifyOTPError = (error) => {
  return {
    type: clientAuthConstants.VERIFY_OTP_ERROR,
    setError: error,
    setLoading: false,
  };
};

const verifyOTPLoading = () => {
  return {
    type: clientAuthConstants.VERIFY_OTP_LOADING,
    setLoading: true,
  };
};
const verifyOTPSuccess = () => {
  return {
    type: clientAuthConstants.VERIFY_OTP_SUCCESS,
  };
};

const verifyOTP = (data, urlId, destination, offerId) => {
  return (dispatch, getState) => {
    dispatch(verifyOTPLoading());
    clienAuthApi
      .verifyUserOtp(data)
      .then((response) => {
        let redirectURL;
        if (destination === "offerTable") {
           redirectURL = `${apiURL}/offer/${urlId}`;
        } else if (destination === "PRE_RTD") {
          redirectURL = `${apiURL}/offer/${urlId}/rtd/${offerId}`
        } else {
          redirectURL = `${apiURL}/contractDocument/${urlId}`;
        }
        window.location.assign(encodeURI(redirectURL));

        dispatch(verifyOTPSuccess());
      })
      .catch((error) => {
        dispatch(
          verifyOTPError(
            error?.response?.data?.message || "Error occured while verifying the OTP!"
          )
        );
      });
  };
};

export const clientAuthHandle = {
  verifyOTP,
  verifyOTPError,
};
