import { Modal, message, Tooltip, Spin, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { PlusCircleFilled, MailOutlined, EditOutlined, DeleteOutlined, UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles/ContactSheet.module.css";
import "./styles/contact.css";
import { Typography } from 'antd';
import { formatPhoneNumber, generateInitialsFromObj } from "../Common/utils/universalFuntions";
import CommonNavbar from "../CreateOffer/components/CommonNavbar";
import { contactSheetActions } from "./state/actions";
import { useParams, useLocation } from "react-router-dom";
import { contractDataHandle } from "../CreateOffer/state/actions";
import SendContactSheet from "./Components/SendContactSheet";
import { decrypt } from "../Common/commondata/commonData";
import AddOrUpdateContact from "./Components/AddOrUpdateContact";
import axios from "axios";
import TitleCompanyTable from "./Components/TitleCompanyTable";

const { confirm } = Modal;
const { Text } = Typography;

const ContactSheet = () => {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const tabId = query.get('tabId');
    const clientId = query.get('reqId');
    let { id } = useParams();
    const [modalState, setModalState] = useState({
        openAddContactModal: false,
        openSendEmailModal: false,
        openIndividualFieldUpdateModal: false,
        source: "",
        key: "",
        selectedKey: "",
        contactSheetId: "",
        ref: {},
        openFor: ""
    });
    const { contactSheetDataLoading, contactSheetDataError, contactSheetData } = useSelector((state) => state.contactSheetReducer);
    const { contractAuthKey, rootDocData } = useSelector((state) => state.createOffer);
    const offerId = rootDocData?.offerId;
    let readOnly = (rootDocData?.builtForSection === "READ_ONLY_DOCUMENT" || rootDocData?.builtForSection !== "CONTACT_SHEET") || false;
    let base64ToNormalDecoded = decodeURIComponent(id);
    let decryptedId = decrypt(base64ToNormalDecoded);

    // On tab close
    window.onbeforeunload = function () {
        localStorage.removeItem(decryptedId);
    };

    useEffect(() => {
        let unmounted = {
            value: false
        };

        let sourceAxios = axios.CancelToken.source();
        if (decryptedId) {
            dispatch(contractDataHandle.getCreateOfferData({urlId:decryptedId, unmounted, sourceAxios}));
        }
        return () => {
            dispatch(contractDataHandle.resetCompressedViewData());
            unmounted.value = true;
            sourceAxios.cancel("Cancelling in cleanup");
            if (decryptedId) {
                localStorage.removeItem(decryptedId);
            }
        };
    }, [dispatch, decryptedId]);

    useEffect(() => {
        if (offerId && contractAuthKey) dispatch(contactSheetActions.getContactSheetData({ offerId, accessToken: contractAuthKey, openFor: rootDocData?.openFor }))
    }, [offerId, contractAuthKey])

    // For textarea auto grow
    useEffect(() => {
        document.querySelectorAll("textarea")?.forEach((textarea) => {
            textarea.parentNode.dataset.replicatedValue = textarea?.value || "";
        })
    }, [contactSheetData])

    const handleDeleteContact = ({ key }) => {
        if (rootDocData?.openFor === "BUYER_AGENT" && (contactSheetData?.contactSheetData[key]?.contactTo === "SELLER_AGENT" || contactSheetData?.contactSheetData[key]?.selectedBy === "SELLER_AGENT" ) || 
            rootDocData?.openFor === "SELLER_AGENT" && (contactSheetData?.contactSheetData[key]?.contactTo === "BUYER_AGENT" || contactSheetData?.contactSheetData[key]?.selectedBy === "BUYER_AGENT")
        ) {
            return;
        }
        if (readOnly) {
            return;
        }
        confirm({
            title: "Are you sure you want to remove this contact from Contact Sheet?",
            content: "",
            okText: "Yes",
            cancelText: "No",
            closable: true,
            keyboard: true,
            icon: <ExclamationCircleOutlined />,
            okType: "danger",
            onCancel: () => { },
            onOk: () => {
                let queryData = {
                    key,
                    type: "DELETE",
                    contactSheetId: contactSheetData?._id,
                    openFor: rootDocData?.openFor,
                }
                dispatch(contactSheetActions.addOrUpdateContact({ offerId, queryData, payload: {}, accessToken: contractAuthKey, openFor: rootDocData?.openFor }));
            },
        })
    }

    const handleFieldUpdate = ({key, ref}) => {
        if (rootDocData?.openFor === "BUYER_AGENT" && (contactSheetData?.contactSheetData[key]?.contactTo === "SELLER_AGENT" || contactSheetData?.contactSheetData[key]?.selectedBy === "SELLER_AGENT" ) || 
            rootDocData?.openFor === "SELLER_AGENT" && (contactSheetData?.contactSheetData[key]?.contactTo === "BUYER_AGENT" || contactSheetData?.contactSheetData[key]?.selectedBy === "BUYER_AGENT")
        ) {
            return;
        }
        if (readOnly) {
            return;
        }
        !readOnly &&
        setModalState((prev) => ({
            ...prev,
            openAddContactModal: false,
            openIndividualFieldUpdateModal: true,
            source: "UPDATE",
            key: key,
            selectedKey: contactSheetData?.contactSheetData[key],
            contactSheetId: contactSheetData?._id,
            ref,
            openFor: rootDocData?.openFor,
        }))
    }

    return <div className={styles.contactSheetScreen}>
        <CommonNavbar source={"ContactSheet"} readOnly={readOnly} decryptedId={decryptedId} tabId={tabId} clientId={clientId}/>
        <div className={styles.contactSheetContainer}>
            <div className={styles.headerContainer}>
                {readOnly ? "" :
                    <div className={`${styles.emailContactSheet} ${styles.noPrint}`} onClick={() => setModalState((prev) => ({ ...prev, openSendEmailModal: true, openFor: rootDocData?.openFor }))}>
                        <div>
                            <MailOutlined style={{ marginRight: 10 }} />
                        </div>
                        <div>
                            Email Contact Sheet
                        </div>
                    </div>}
                {modalState?.openSendEmailModal && <SendContactSheet offerId={offerId} accessToken={contractAuthKey} modalState={modalState} setModalState={setModalState} />}
                <div className={styles.title}>CONTACT SHEET</div>
                {readOnly ? "" :
                    <section className={`${styles.topToolsSection} ${styles.noPrint}`}>
                        <div className={styles.plusCircle}>
                            <Tooltip title={"Add Contact"} placement="right">
                                <PlusCircleFilled
                                    onClick={() => setModalState((prev) => ({
                                        ...prev,
                                        openAddContactModal: true,
                                        source: "ADD",
                                        contactSheetId: contactSheetData?._id,
                                        openFor: rootDocData?.openFor,
                                    }))}
                                />
                            </Tooltip>
                        </div>
                    </section>}
            </div>

            {
                (modalState?.openAddContactModal || modalState?.openIndividualFieldUpdateModal) &&
                <AddOrUpdateContact offerId={offerId} accessToken={contractAuthKey} setModalState={setModalState} modalState={modalState} />}

            {
                contactSheetDataLoading ? <Spin size="large" style={{ height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }} /> :
                    contactSheetDataError ? message.error(contactSheetDataError) :
                        contactSheetData ?
                            <>
                                <div className={styles.contactSheetGridDesign}>
                                    <section className={styles.propertySection}>
                                        <div className={styles.propertyAddress}>{contactSheetData?.propertyAddress}</div>
                                    </section>
                                    <section className={styles.contactGrid}>
                                        <div id={styles.SELLER_AGENT} className={styles.gridScroll}>
                                            {
                                                contactSheetData?.contactSheetData && Object.keys(contactSheetData?.contactSheetData)?.length &&
                                                Object.keys(contactSheetData?.contactSheetData).map((data) => { // data is the key here which is unique key
                                                    const agentInitials = generateInitialsFromObj(contactSheetData?.contactSheetData[data]);
                                                    if (contactSheetData?.contactSheetData[data].contactTo === "SELLER_AGENT" || contactSheetData?.contactSheetData[data].selectedBy === "SELLER_AGENT")
                                                    return (
                                                        <div className={styles.detailsBox} key={data}>
                                                            <div className={styles.headerBox}>
                                                                {readOnly ? "" :
                                                                    <div className={styles.dummy}></div>}
                                                                <div className={styles.title} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Type", updatingKey: "type"}})}>{contactSheetData?.contactSheetData[data]?.type}</div>
                                                                {readOnly ? "" :
                                                                    <div className={styles.tools}>
                                                                        <div className={`${styles.editContact} ${styles.noPrint}`} onClick={() => {
                                                                            setModalState((prev) => ({
                                                                                ...prev,
                                                                                openAddContactModal: true,
                                                                                source: "UPDATE",
                                                                                key: data,
                                                                                selectedKey: contactSheetData?.contactSheetData[data],
                                                                                contactSheetId: contactSheetData?._id,
                                                                            }))
                                                                        }}
                                                                        style={{display: 'none'}}
                                                                        >
                                                                            <Tooltip title={"Edit Contact"} placement="bottom">
                                                                                <EditOutlined />
                                                                            </Tooltip>
                                                                        </div>
                                                                        {rootDocData?.openFor === "BUYER_AGENT" ? "" :
                                                                        <div className={`${styles.deleteContact} ${styles.noPrint}`} onClick={() => handleDeleteContact({ key: data })}
                                                                            style={{display: (contactSheetData?.contactSheetData[data]?.type === "SELLER AGENT" || contactSheetData?.contactSheetData[data]?.type === "BUYER AGENT") ? 'none' : 'block'}}
                                                                            >
                                                                            <Tooltip title={"Delete Contact"} placement="bottom">
                                                                                <DeleteOutlined />
                                                                            </Tooltip>
                                                                        </div>}
                                                                    </div>}
                                                            </div>
                                                            <hr />
                                                            <div className={styles.fullDetails}>
                                                                <table>
                                                                    {
                                                                        contactSheetData?.contactSheetData[data]?.type !== 'TITLE COMPANY' ?
                                                                            <>
                                                                                <tr>
                                                                                    <td colSpan={3}>
                                                                                        <div className={styles.flexContainer}>
                                                                                            <div className={styles.brokerageLogo}>
                                                                                                {contactSheetData?.contactSheetData[data]?.brokerageLogo ? <img src={contactSheetData?.contactSheetData[data]?.brokerageLogo} /> : ""}
                                                                                            </div>
                                                                                            <div className={styles.image} style={{ textAlign: "left" }}>
                                                                                                <Avatar src={contactSheetData?.contactSheetData[data]?.profileImg?.original} className={styles.userAvatar} size={45}>
                                                                                                    {!contactSheetData?.contactSheetData[data]?.profileImg?.original && agentInitials ? agentInitials : <UserOutlined style={{ fontSize: "24px" }} />}
                                                                                                </Avatar>
                                                                                            </div>
                                                                                            <div></div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Name", updatingKey: "fullName"}})}>Name:</td>
                                                                                    <td className={`${styles.value} ${styles.fullName}`} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Name", updatingKey: "fullName"}})}>{contactSheetData?.contactSheetData[data]?.fullName || ""}</td>
                                                                                </tr>
                                                                                {/* IF BUYER AGENT OPENED THEN SELLER DETAILS THAT ADDED BY SELLER_AGENT SHOULD NOT BE FULLY VISIBLE */}
                                                                                {rootDocData?.openFor === "BUYER_AGENT" && contactSheetData?.contactSheetData[data]?.contactTo === "SELLER_AGENT" && ["SELLER"].includes(contactSheetData?.contactSheetData[data]?.type) ? "" : <> 
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Email", updatingKey: "email"}})}>Email:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.email ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.email}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Email", updatingKey: "email"}})}>{contactSheetData?.contactSheetData[data]?.email}</span></Text> : ""}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Mobile No.", updatingKey: "mobilePhoneNumber"}})}>Mobile:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.mobilePhoneNumber ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.mobilePhoneNumber}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Mobile No.", updatingKey: "mobilePhoneNumber"}})}>{formatPhoneNumber(contactSheetData?.contactSheetData[data]?.mobilePhoneNumber)}</span></Text> : ""}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Office No.", updatingKey: "officePhoneNumber"}})}>Office Ph.:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.officePhoneNumber ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.officePhoneNumber}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Office No.", updatingKey: "officePhoneNumber"}})}>{formatPhoneNumber(contactSheetData?.contactSheetData[data]?.officePhoneNumber)}</span></Text> : ""}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Fax No.", updatingKey: "fax"}})}>Fax:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.fax ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.fax}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Fax No.", updatingKey: "fax"}})}>{formatPhoneNumber(contactSheetData?.contactSheetData[data]?.fax)}</span></Text> : ""}</td>
                                                                                </tr></>}
                                                                            </> : ""
                                                                    }
                                                                    {
                                                                        contactSheetData?.contactSheetData[data]?.type === 'TITLE COMPANY' ?
                                                                        <TitleCompanyTable individualContactData={contactSheetData?.contactSheetData[data]} data={data} handleFieldUpdate={handleFieldUpdate}/> : ""

                                                                    }
                                                                </table>
                                                            </div>
                                                            {rootDocData?.openFor === "BUYER_AGENT"  && contactSheetData?.contactSheetData[data]?.contactTo === "SELLER_AGENT" && ["SELLER"].includes(contactSheetData?.contactSheetData[data]?.type) ? "" : <> 
                                                            <hr />
                                                            <div className={styles.notesSection} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Notes", updatingKey: "notes"}})}>
                                                                <div className={styles.noteLabel}>
                                                                    Notes
                                                                </div>
                                                                <div className={styles.textareaNotes}>
                                                                    <span className={styles.growWithContent}>
                                                                        <textarea disabled={readOnly || true} name="notes" className={`notes ${data}`}>{contactSheetData?.contactSheetData[data]?.notes || ""}</textarea>
                                                                    </span>
                                                                </div>
                                                            </div></>}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div id={styles.BUYER_AGENT} className={styles.gridScroll}>
                                            {
                                                contactSheetData?.contactSheetData && Object.keys(contactSheetData?.contactSheetData)?.length &&
                                                Object.keys(contactSheetData?.contactSheetData).map((data) => { // data is the key here which is unique key
                                                    const agentInitials = generateInitialsFromObj(contactSheetData?.contactSheetData[data]);
                                                    if (contactSheetData?.contactSheetData[data].contactTo === "BUYER_AGENT" || contactSheetData?.contactSheetData[data].selectedBy === "BUYER_AGENT")
                                                    return (
                                                        <div className={styles.detailsBox} key={data}>
                                                            <div className={styles.headerBox}>
                                                                {readOnly ? "" :
                                                                    <div className={styles.dummy}></div>}
                                                                <div className={styles.title} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Type", updatingKey: "type"}})}>{contactSheetData?.contactSheetData[data]?.type}</div>
                                                                {readOnly ? "" :
                                                                    <div className={styles.tools}>
                                                                        <div className={`${styles.editContact} ${styles.noPrint}`} onClick={() => {
                                                                            setModalState((prev) => ({
                                                                                ...prev,
                                                                                openAddContactModal: true,
                                                                                source: "UPDATE",
                                                                                key: data,
                                                                                selectedKey: contactSheetData?.contactSheetData[data],
                                                                                contactSheetId: contactSheetData?._id,
                                                                            }))
                                                                        }}
                                                                        style={{display: 'none'}}
                                                                        >
                                                                            <Tooltip title={"Edit Contact"} placement="bottom">
                                                                                <EditOutlined />
                                                                            </Tooltip>
                                                                        </div>
                                                                        {rootDocData?.openFor === "SELLER_AGENT" ? "" :
                                                                        <div className={`${styles.deleteContact} ${styles.noPrint}`} onClick={() => handleDeleteContact({ key: data })}
                                                                            style={{display: (contactSheetData?.contactSheetData[data]?.type === "SELLER AGENT" || contactSheetData?.contactSheetData[data]?.type === "BUYER AGENT") ? 'none' : 'block'}}
                                                                            >
                                                                            <Tooltip title={"Delete Contact"} placement="bottom">
                                                                                <DeleteOutlined />
                                                                            </Tooltip>
                                                                        </div>}
                                                                    </div>}
                                                            </div>
                                                            <hr />
                                                            <div className={styles.fullDetails}>
                                                                <table>
                                                                    {
                                                                        contactSheetData?.contactSheetData[data]?.type !== 'TITLE COMPANY' ?
                                                                            <>
                                                                                <tr>
                                                                                    <td colSpan={3}>
                                                                                        <div className={styles.flexContainer}>
                                                                                            <div className={styles.brokerageLogo}>
                                                                                                {contactSheetData?.contactSheetData[data]?.brokerageLogo ? <img src={contactSheetData?.contactSheetData[data]?.brokerageLogo} /> : ""}
                                                                                            </div>
                                                                                            <div className={styles.image} style={{ textAlign: "left" }}>
                                                                                                <Avatar src={contactSheetData?.contactSheetData[data]?.profileImg?.original} className={styles.userAvatar} size={45}>
                                                                                                    {!contactSheetData?.contactSheetData[data]?.profileImg?.original && agentInitials ? agentInitials : <UserOutlined style={{ fontSize: "24px" }} />}
                                                                                                </Avatar>
                                                                                            </div>
                                                                                            <div></div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Name", updatingKey: "fullName"}})}>Name:</td>
                                                                                    <td className={`${styles.value} ${styles.fullName}`}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Name", updatingKey: "fullName"}})}>{contactSheetData?.contactSheetData[data]?.fullName || ""}</span></td>
                                                                                </tr>
                                                                                {rootDocData?.openFor === "SELLER_AGENT" && contactSheetData?.contactSheetData[data]?.contactTo === "BUYER_AGENT" && ["BUYER"].includes(contactSheetData?.contactSheetData[data]?.type) ? "" : <> 
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Email", updatingKey: "email"}})}>Email:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.email ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.email}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Email", updatingKey: "email"}})}>{contactSheetData?.contactSheetData[data]?.email}</span></Text> : ""}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Mobile No.", updatingKey: "mobilePhoneNumber"}})}>Mobile:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.mobilePhoneNumber ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.mobilePhoneNumber}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Mobile No.", updatingKey: "mobilePhoneNumber"}})}>{formatPhoneNumber(contactSheetData?.contactSheetData[data]?.mobilePhoneNumber)}</span></Text> : ""}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Office No.", updatingKey: "officePhoneNumber"}})}>Office Ph.:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.officePhoneNumber ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.officePhoneNumber}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Office No.", updatingKey: "officePhoneNumber"}})}>{formatPhoneNumber(contactSheetData?.contactSheetData[data]?.officePhoneNumber)}</span></Text> : ""}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className={styles.label} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Fax No.", updatingKey: "fax"}})}>Fax:</td>
                                                                                    <td className={styles.value}>{contactSheetData?.contactSheetData[data]?.fax ? <Text copyable={{text: contactSheetData?.contactSheetData[data]?.fax}}><span onClick={()=> handleFieldUpdate({key: data, ref: {label: "Fax No.", updatingKey: "fax"}})}>{formatPhoneNumber(contactSheetData?.contactSheetData[data]?.fax)}</span></Text> : ""}</td>
                                                                                </tr></>}
                                                                            </> : ""
                                                                    }
                                                                    {
                                                                        contactSheetData?.contactSheetData[data]?.type === 'TITLE COMPANY' ?
                                                                        <TitleCompanyTable individualContactData={contactSheetData?.contactSheetData[data]} data={data} handleFieldUpdate={handleFieldUpdate}/> : ""
                                                                    }
                                                                </table>
                                                            </div>
                                                            {rootDocData?.openFor === "SELLER_AGENT" && contactSheetData?.contactSheetData[data]?.contactTo === "BUYER_AGENT" && ["BUYER"].includes(contactSheetData?.contactSheetData[data]?.type) ? "" : <> 
                                                            <hr />
                                                            <div className={styles.notesSection} onClick={()=> handleFieldUpdate({key: data, ref: {label: "Notes", updatingKey: "notes"}})}>
                                                                <div className={styles.noteLabel}>
                                                                    Notes
                                                                </div>
                                                                <div className={styles.textareaNotes}>
                                                                    <span className={styles.growWithContent}>
                                                                        <textarea disabled={readOnly || true} name="notes" className={`notes ${data}`}>{contactSheetData?.contactSheetData[data]?.notes || ""}</textarea>
                                                                    </span>
                                                                </div>
                                                            </div></>}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </section>
                                </div>
                            </> : ""
            }
        </div>
    </div>
}


export { ContactSheet };
